// import styled from "styled-components";
import { Box, Typography, Paper, Button } from "components";
import { useContext } from "react";
import { ACTION, GameContext, getLeaderboard } from "../gameManager";

export function LeaderboardScene() {
  const { dispatch } = useContext(GameContext);
  const scores = getLeaderboard().slice(0, 10);
  return (
    <Box>
      {scores.map((score, index) => (
        <Box py={.5} key={index} style={{ minWidth: 320 }}>
          <Paper variant="outlined">
            <Box p={1} display="flex" alignItems="center">
              <Box pr={1}>
                <Typography as="h3">{index + 1}</Typography>
              </Box>
              <Box>
                <Typography>{score}</Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
      ))}
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          dispatch({
            type: ACTION.RESET
          })
        }}
      >Back</Button>
    </Box>
  )
}