import styled from 'styled-components';

export interface BackdropProps {
  invisible?: boolean;
  color?: string;
  opacity?: number;
  position?: 'fixed' | 'absolute';
  blur?: number;
  zIndex?: number;
}

export const Backdrop = styled.div<BackdropProps>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: ${props => props.zIndex ?? -1};
  position: ${props => props.position};
  align-items: center;
  justify-content: center;
  backdrop-filter: ${props => !props.invisible ? `blur(${props.blur ?? 1}px)`: ''};
  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index:-1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${props => props.opacity};
    background-color: ${(props): string => (props.invisible ? 'transparent' : (
      props.color || props.theme.palette.background.main
    ))};
  }
`;

Backdrop.defaultProps = {
  invisible: false,
  color: undefined,
  opacity: 0.75,
  position: 'fixed',
}