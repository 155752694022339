import styled from 'styled-components';
import { Box, BoxProps } from '../Box';

export interface PaperProps extends BoxProps {
  variant?: 'outlined';
  transparent?: boolean;
  borderWidth?: number;
  borderType?: 'solid' | 'dashed';
}

export const Paper = styled(Box) <PaperProps>`
  /* box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.5), 0px 1px 1px 0px rgba(0,0,0,0.3), 0px 1px 3px 0px rgba(0,0,0,0.3); */
  border-radius: ${(props) => props.theme.borderRadius}px;
  background-color: ${(props) => props.transparent ? '' : props.theme.palette.background.light};
  border: ${props => {
    if (props.variant !== 'outlined') return;
    return `${props.borderWidth ?? 1}px ${props.borderType ?? 'solid'} ${props.theme.palette.divider}`
  }}
`;

Paper.displayName = 'Paper';

