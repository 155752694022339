import { Children, cloneElement } from 'react';
import styled from 'styled-components';
import { ButtonBaseProps } from '../Button';

export interface ButtonGroupProps extends ButtonBaseProps {
  orientation?: 'vertical' | 'horizontal';
  children: any; // Todo: change this to type of button
}

interface IButtonContainer {
  fullWidth: ButtonBaseProps['fullWidth'];
  orientation: ButtonGroupProps['orientation'];
}

const getBorderRaidus = (orientation: IButtonContainer['orientation']): string => {
  switch (orientation) {
    case 'vertical':
      return `
      flex-direction: column;
      > * {
        &:not(:last-child) {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
        &:not(:first-child) {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      
        &:not(:last-child):not(:hover):not(:focus):not(:active):not(.active) {
          border-bottom-color: transparent;
        }
        
        &:hover,
        &:focus,
        &.active {
          & + * {
            border-top-color: transparent;
          }
        }
      }
      `;
    default:
      return `
      flex-direction: rows;
      > * {
        &:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;    
        }
    
        &:not(:last-child):not(:hover):not(:focus):not(:active):not(.active) {
          border-right-color: transparent;
        }

        &:hover,
        &:focus,
        &.active {
          & + * {
            border-left-color: transparent;
          }
        }
      
        &:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;    
        }
      }
    `;
  }
};

const ButtonGroupContainer = styled.div<IButtonContainer>`
  display: inline-flex;
  ${(props): string => props.fullWidth ? 'width: 100%;' : ''};
  ${(props): string => getBorderRaidus(props.orientation)}
`;

ButtonGroupContainer.displayName = 'ButtonGroupContainer';

export const ButtonGroup = (props: ButtonGroupProps): JSX.Element => {
  const {
    orientation, children, ...rest
  } = props;
  return (
    <ButtonGroupContainer fullWidth={!!(false)} orientation={orientation}>
      {Children.map(children, (child) => cloneElement(child, { ...rest }))}
    </ButtonGroupContainer>
  );
};
