import { useCallback, useContext, useEffect, useState } from "react"
import { useForm, Controller } from "react-hook-form";
import {
  getPercentageValue,
  getPMIRate,
  getPMIValuePerMonth,
  getPrincipalValue,
  numberToPercentage,
  isBiggerThanCero,
  isNotEmpty
} from "helpers";
import { HomeCountext } from "./Context"
import { DataBox } from "./DataBox";
import {
  Modal,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Box,
  Grid,
  GridItem,
  TextField,
  DialogActions,
  Button
} from "components";
import { ACTION } from './reducer';

interface DialogProps {
  open: boolean;
  onClose: () => void;
}

function DialogContainer(props: DialogProps) {
  const {
    open,
    onClose,
  } = props;
  const {state, dispatch} = useContext(HomeCountext);
  const {
    salesPrice,
    downpayment,
    intrestRate,
    loanTermYears,
    closingCost,
  } = state;

  const fields = useCallback((): {
    label: string,
    name: string,
    before: string,
    validate?: (v: string | number) => boolean,
    value: number | string,
    type?: "number" | "text" | "password" | undefined
  }[] => [
    {
      label: 'Sales Price',
      name: 'salesPrice',
      before: '$',
      validate: isBiggerThanCero,
      value: salesPrice,
    },
    {
      label: 'Downpayment ',
      name: 'downpayment',
      before: '%',
      value: downpayment
    },
    {
      label: 'Intrest Rate ',
      name: 'intrestRate',
      before: '%',
      value: intrestRate
    },
    {
      label: 'Loan Terms ',
      name: 'loanTermYears',
      before: 'Yr',
      value: loanTermYears
    },
    {
      label: 'Closing Cost',
      name: 'closingCost',
      before: '%',
      value: closingCost * 100
    }
  ], [closingCost, downpayment, intrestRate, loanTermYears, salesPrice])

  const getDefaultValues = useCallback(() => {
    let fieldsObj: {[key: string]: string | number} = {};

    fields().forEach(({name, value}) => {
      fieldsObj[name] = value
    })
    
    return fieldsObj
  }, [fields])
  const {
    control,
    handleSubmit,
    reset,
    formState: {
      isValid,
    },
  } = useForm({
    mode: "onChange",
    defaultValues: getDefaultValues()
  });

  useEffect(() => {
    reset(getDefaultValues())
  }, [getDefaultValues, reset, state])


  const closeHandler = () => {
    onClose();
  }
  const onSubmit = (formData: any) => {
    const payload = {
      salesPrice: Number(formData.salesPrice),
      downpayment: Number(formData.downpayment),
      intrestRate: Number(formData.intrestRate),
      loanTermYears: Number(formData.loanTermYears),
      closingCost: Number(formData.closingCost) / 100
    }
    dispatch({
      type: ACTION.UPDATE_MORTGAGE,
      payload
    });
    closeHandler();
  }

  const isFormValid = isValid;

  return (
    <Modal
      active={open}
      onClose={closeHandler}
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Dialog loading={false}>
          <DialogTitle>
            <Typography as="small" block>Edit</Typography>
            Mortgage
          </DialogTitle>
          <DialogContent>
            <Grid gap={1}>
              {fields().map((field) => {
                const { 
                  validate = isNotEmpty,
                  name: fieldName,
                  type = 'number',
                  label,
                  before = '$'
                } = field 
                return (
                  <GridItem key={fieldName}>
                    <Controller
                      control={control}
                      name={fieldName as any}
                      rules={{ 
                        required: true, 
                        validate
                      }}
                      render={({
                        field: { onChange, value, name },
                        fieldState: { invalid },
                      }) => {
                        return (
                          <TextField
                            type={type}
                            before={before}
                            name={name}
                            id={name}
                            label={label}
                            isValid={!invalid}
                            required
                            fullWidth
                            value={value}
                            onChange={onChange}
                          />
                        )
                      }}
                    />
                  </GridItem>
                )
              })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              disabled={!isFormValid}
            >
              <Box as="span" display="flex" alignItems="center">
                Update
              </Box>
            </Button>
            <Button onClick={closeHandler}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </form>
    </Modal>
  )
}


export function MortgageContainer() {
  const {state} = useContext(HomeCountext);
  const {
    salesPrice,
    downpayment,
    intrestRate,
    loanTermYears,
    closingCost,
    mortgageTotal,
    buyPayment
  } = state;
  const [open, setOpen] = useState(false);
  const principal = getPrincipalValue(salesPrice, downpayment);
  const downPayment = getPercentageValue(salesPrice, downpayment);
  const loanAmount = salesPrice - downPayment;
  const pmiValue = getPMIValuePerMonth(salesPrice, downPayment);
  const items = [
    {
      name: 'Sales Price',
      value: salesPrice,
      uom: '$'
    },
    {
      name: `Down Payment (${downpayment}%)`,
      value: downPayment,
      uom: '$'
    },
    {
      name: `Loan Amount (${100 - downpayment}%)`,
      value: loanAmount,
      uom: '$'
    },
    {
      name: 'Closing Cost',
      value: salesPrice * closingCost,
      uom: '$'
    },
    {
      name: `Property Mortgage Insurance - PMI ${pmiValue ? `(${numberToPercentage(getPMIRate())})` : ''}`,
      value: pmiValue || 0,
      uom: '$'
    },
    {
      name: 'Intrest Rate',
      value: intrestRate,
      uom: '%'
    },
    {
      name: 'Loan Years',
      value: loanTermYears,
    },
    {
      name: 'Principal',
      value: principal,
      uom: '$'
    },
  ]

  const openHandler = () => {
    setOpen(true)
  }

  const closeHandler = () => {
    setOpen(false)
  }

  return (
    <>
      <DataBox
        editClickHandler={openHandler}
        title='Mortgage'
        items={items}
        total={[
          {
            title: 'Buy In Payment',
            value: buyPayment,
          },
          {
            title: 'Monthly Payments',
            value: mortgageTotal,
          }
        ]}
      />
      <DialogContainer
        onClose={closeHandler}
        open={open}
      />
    </>
  )
}