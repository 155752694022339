import styled from 'styled-components';
import { Paper, PaperProps } from '../Paper'; 
import { Box } from '../Box'; 
import { Backdrop } from '../Backdrop'; 
import { Spinner } from '../Spinner'; 

export const CardHeader = styled(Box)``;
CardHeader.defaultProps = {
  p: 1,
}
export const CardMedia = styled(Box)``;
CardMedia.defaultProps = {
  p: 1,
}
export const CardContent = styled(Box)``;
CardContent.defaultProps = {
  p: 1,
}
export const CardFooter = styled(Box)`
`;
CardFooter.defaultProps = {
  pt: 0,
  px: 1,
  pb: 1,
  display: 'flex',
  justify: 'flex-end'
}

const Wrapper = styled(Paper)`
  position: relative;
`;

export interface CardProps extends PaperProps {
  loading?: boolean;
  children: any;
}

export const Card = ({ children, loading, ...rest }: CardProps): JSX.Element => {
  return (
    <Wrapper {...rest}>
      {children}
      {loading && <Backdrop position="absolute" blur={0} zIndex={1}><Spinner /></Backdrop>}
    </Wrapper>
  );
};

Card.displayName = 'Card';

