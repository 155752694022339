import React from 'react';
import styled from 'styled-components';

// TODO: _ IMPORTANT finish list this is first to do
// TODO  Add Icon functionality
export interface ListItemProps {
  gutter?: boolean;
  padding?: boolean;
  sticky?: boolean;
  selected?: boolean;
}

export const ListItem = styled.li<ListItemProps>`
  margin: 0;
  text-decoration: none;
  display: block;

  background: ${(props): string => props.selected ? props.theme.palette.hover : ''};


  &[role=button],&[role=option] {
    cursor: pointer;
  }

  ${(props): string => props.sticky ? `
    top: 0;
    z-index: 1;
    position: sticky;
    background: inherit;
  ` : ''};

  ${(props): string => props.gutter ? `
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  ` : ''}

  ${(props): string => props.padding ? `
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  ` : ''}
`;

ListItem.defaultProps = {
  padding: true,
  gutter: true,
  role: 'button',
};

ListItem.displayName = 'ListItem';

export const ListBase = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;

  ${ListItem} {
    &:hover {
      background: ${(props): string => props.theme.palette.hover};
    }
  }
`;

export interface ListProps {
  padding?: boolean;
  children: any;
  as?: keyof Pick<HTMLElementTagNameMap, 'ul' | 'dl' | 'ol'>; 
  styles?: any
}


export const List = (props: ListProps): JSX.Element => {
  const { children, padding, as, styles } = props;
  const items = React.Children.map(children, (child) => {
    if(child?.type?.__docgenInfo?.displayName === ListItem.displayName) {
      const asChild = as === 'dl' && child.type?.target === 'li' ? 'dt' :   child.type?.target ?? 'li';
      return React.cloneElement(child, { padding, as: asChild });
    }
    return child;
  });
  return (
    <ListBase as={(as ?? 'ul') as any} style={styles ?? {}}>
      {items}
    </ListBase>
  );
};
