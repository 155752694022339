import { convertHexToRgba } from '../../helpers';
import styled, { DefaultTheme } from 'styled-components';
import { BoxSpacingProps, BoxProps } from '../Box'

interface setVariantArgs {
  variant?: ButtonBaseProps['variant'];
  color: ButtonBaseProps['color'];
  theme: DefaultTheme;
  active?: boolean;
}

export const setVariant = (args: setVariantArgs): string => {
  const { variant, color, theme, active } = args;
  let style = '';
  let hover = '';
  let focus = '';
  const borderOpacity = 35;
  const hoverOpacity = 75;

  switch (color) {
    case 'primary':
      if (variant === 'contained') {
        style = `
          color: ${theme.palette.background.contrastText};
          background-color: ${theme.palette.primary.main};
        `;
        hover = `
          background-color: ${theme.palette.primary.dark};
        `;
        focus = `
          background-color: ${theme.palette.primary.dark};
        `;
        break;
      }
      if (variant === 'outlined') {
        style = `
          background-color: ${theme.palette.primary.main};
          color: ${theme.palette.background.contrastText};
          border: 1px solid ${convertHexToRgba(theme.palette.background.contrastText, 15)};
        `;
        hover = `
          background-color: ${convertHexToRgba(theme.palette.background.contrastText, 2)};
        `;
        focus = `
          background-color: ${convertHexToRgba(theme.palette.background.contrastText, 2)};
        `;
        break;
      }
      style = `color: ${theme.palette.background.contrastText};`;
      hover = `
        background-color: ${convertHexToRgba(theme.palette.background.contrastText, 5)};
      `;
      focus = `
        background-color: ${convertHexToRgba(theme.palette.background.contrastText, 5)};
      `;
      break;
    default:
      if (variant === 'contained') {
        style = `
          background-color: ${theme.palette.primary.main};
          color: ${theme.palette.primary.contrastText};
        `;
        hover = `
          background-color: ${convertHexToRgba(theme.palette.background.main, hoverOpacity)};
        `;
        focus = `
          background-color: ${convertHexToRgba(theme.palette.background.main, hoverOpacity)} !important;
        `;
        break;
      }
      if (variant === 'outlined') {
        style = `
          color: ${theme.palette.primary.main};
          border: 1px solid ${convertHexToRgba(theme.palette.primary.main, borderOpacity)};
        `;
        hover = `
          border-color: ${convertHexToRgba(theme.palette.primary.main, 100)};
          background-color: ${convertHexToRgba(theme.palette.primary.main, 5)};
        `;
        focus = `
          border-color: ${convertHexToRgba(theme.palette.primary.main, 100)};
          background-color: ${convertHexToRgba(theme.palette.primary.main, 5)};
        `;
        break;
      }
      style = `color: ${theme.palette.primary.main};`;
      hover = `
        background-color: ${convertHexToRgba(theme.palette.primary.main, 5)};
      `;
      focus = `
        background-color: ${convertHexToRgba(theme.palette.primary.main, 5)};
      `;
      break;
  }

  return `
    ${style}
    ${active ? focus : ''}
    &:active, &.active {
      ${focus}
    }
    &:hover {
      ${hover}
    }
    &:focus {
      ${focus}
    }
  `;
};

export const getSize = (size: string): number => {
  switch (size) {
    case 'xs':
      return 1.25;
    case 'sm':
      return 2;
    case 'lg':
      return 3.5;
    case 'md':
    default:
      return 2.5;
  }
};

export interface ButtonBaseProps extends BoxSpacingProps {
  variant?: 'contained' | 'outlined';
  color?: 'primary' | 'secondary' | 'error';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  fullWidth?: boolean;
  justify?: BoxProps['justify'];
  alignItems?: BoxProps['alignItems'];
}

export interface ButtonProps extends ButtonBaseProps {
  rounded?: boolean;
  active?: boolean;
  children: any;
}

export const Button = styled.button<ButtonProps>`
  box-sizing: border-box;
  white-space: nowrap;
  min-width: 64px;
  height: ${(props): number => getSize(props.size || 'md')}rem;
  line-height: ${(props): number => getSize(props.size || 'md')}rem;
  padding: 0 1rem;
  font-size: ${(props): number => getSize(props.size || 'md') * .4}rem;
  font-weight: 500;
  border-radius: ${(props): string => (props.rounded ? `calc(${getSize(props.size || 'md')}rem / 2)` : `${props.theme.borderRadius}px`)};
  margin: 0;
  display: inline-flex;
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justify};
  cursor: pointer;
  position: relative;
  transition: border 150ms ease-in;
  text-decoration: none;
  text-transform: capitalize;
  background-color: transparent;
  border: 0;

  ${(props) => props.fullWidth ? 'width: 100%;' : ''};
  ${({ variant, color, theme, active }) => setVariant({
  variant,
  color,
  theme,
  active
})}

  margin-top: ${(props) => {
    return props.theme.spacing.space(props.pt || props.py || props.p || 0);
  }};
  margin-bottom: ${(props) => {
    return props.theme.spacing.space(props.pb || props.py || props.p || 0);
  }};
  margin-left: ${(props) => {
    return props.theme.spacing.space(props.pl || props.px || props.p || 0);
  }};
  margin-right: ${(props) => {
    return props.theme.spacing.space(props.pr || props.px || props.p || 0);
  }};

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  &:focus {
    outline: 1px solid ${props => props.theme.palette.outline};
  }
`;

Button.defaultProps = {
  type: 'button',
  justify: 'center',
  alignItems: 'center',
  size: 'md'
}
