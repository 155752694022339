
interface MapRangeArgs {
  value: number;
  inputMin: number;
  inputMax: number;
  outputMin: number;
  outputMax: number;
  clamp?: boolean;
}

export const mapRange = (args: MapRangeArgs) => {
  const { value, inputMin, inputMax, outputMin, outputMax, clamp = false } = args;

  if (Math.abs(inputMin - inputMax) < Number.EPSILON) {
    return outputMin;
  }

  const outVal = (
    (value - inputMin) / (inputMax - inputMin) * (outputMax - outputMin) + outputMin
  );


  if (clamp) {
    if (outputMax < outputMin) {
      if (outVal < outputMax) return outputMax
      if (outVal > outputMin) return outputMin;
    } else {
      if (outVal > outputMax) return outputMax;
      if (outVal < outputMin) return outputMin;
    }
  }

  return outVal;
}

export const numberToDollars = (amount: number) => new Intl.NumberFormat(
  'en-US', { style: 'currency', currency: 'USD' }).format(amount);
export const numberToPercentage = (amount: number) => new Intl.NumberFormat(
  'en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount);


export const getPrincipalValue = (salesPrice: number, downpayment: number) => {
  return salesPrice - (salesPrice * (downpayment / 100))
}


interface GetMortgageValueArgs {
  salesPrice: number;
  downpayment: number;
  intrestRate: number;
  loanTermYears: number;
}
export const getMortgageValue = ({
  salesPrice, //
  downpayment,
  intrestRate,
  loanTermYears
}: GetMortgageValueArgs): number => {
  // M = P [ I ( 1 + I )^N ] / [ ( 1 + I )^N – 1 ]
  const p = salesPrice - (salesPrice * (downpayment / 100));
  const i = (intrestRate / 100) / 12;
  const n = loanTermYears * 12;
  const result = p * (i * (Math.pow((1 + i), n))) / (Math.pow((1 + i), n) - 1);
  return Number(parseFloat(result.toString()).toFixed(6));
}

export const getPropertyTaxes = (value: number, taxRate: number = 1.19) => {
  return value * (taxRate / 100)
}

interface GetPMIRateArgs {
  creditScore?: number;
  propertyType?: Property['propertyType'];
  numberOfBorrower?: number;
}
export const getPMIRate = (args?: GetPMIRateArgs) => {
  const {
    creditScore,
    propertyType,
    numberOfBorrower,
  } = { creditScore: 689, numberOfBorrower: 1, ...args };
  const lowEnd = 0.0022;
  const highEnd = 0.0225;

  let diff = highEnd - lowEnd;
  let discount = 0;

  if (creditScore > 765) {
    discount += .50;
  }
  if (propertyType === 'singleFamily') {
    discount += .25;
  }
  if (numberOfBorrower > 1) {
    discount += .25;
  }
  const avg = (lowEnd + (highEnd - diff * discount)) / 2;
  return avg;
}


/*
When calculating this rate, we put in a 5% down loan,
a $200,000 loan amount, one borrower, a 760 credit score,
single family residence, and we selected it to be a primary residence.
With this, you’d be looking at $60 a month at a mortgage insurance factor of 0.36%.
They calculate the amount by taking 0.36% of the loan amount and dividing it by 12,
to get your monthly amount. You’ll pay mortgage insurance monthly and then there
is an adjustment period after the 10th year. In this example,
the MI rate would go down to 0.2% at the 10 year mark; however,
the adjustment period isn’t always hit because a lot of people
refinance before that 10th year.
*/
// PMI costs range between 0.22% to 2.25% of your mortgage(Opens Overlay).
// const pmiValue = (loanAmount * 0.0022) / 12;
export const getPMIValuePerMonth = (salesPrice: number, downPayment: number,) => {
  const loanAmount = salesPrice - downPayment;
  const percentagePaid = (downPayment / salesPrice) * 100;
  if (percentagePaid >= 20) return 0;
  return (loanAmount * getPMIRate()) / 12
}

export const getPercentageValue = (total: number, percent: number) => total * (percent / 100);

export const getRecommendedRent = (cost = 0, targetRent = 0, marketRent = 0) => {
  console.log({ cost, targetRent, marketRent });
  console.log('here');
  return 0;
}

export const stringToNumber = (str: string) => {
  return str.replace(/[^0-9]/g, '');
}