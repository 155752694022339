import styled, { DefaultTheme } from 'styled-components';

export interface BoxSpacingProps {
  p?: number;
  py?: number;
  px?: number;
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
}

export interface BoxProps extends BoxSpacingProps {
  size?: keyof DefaultTheme['breakPoints']
  display?: 'flex' | 'inline-block' | 'inline-flex';
  textAlign?: 'left' | 'center' | 'right';
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'start' | 'end' | 'left' | 'right';
  alignItems?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'first baseline' | 'last baseline' | 'start' | 'end' | 'self-start' | 'self-end';
  alignContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch' | 'start' | 'end' | 'baseline' | 'first baseline' | 'last baseline';
  borderRadius?: boolean;
}

export const Box = styled.div<BoxProps>`
  box-sizing: border-box;
  padding-top: ${(props) => {
    return props.theme.spacing.space(props.pt || props.py || props.p || 0);
  }};
  padding-bottom: ${(props) => {
    return props.theme.spacing.space(props.pb || props.py || props.p || 0);
  }};
  padding-left: ${(props) => {
    return props.theme.spacing.space(props.pl || props.px || props.p || 0);
  }};
  padding-right: ${(props) => {
    return props.theme.spacing.space(props.pr || props.px || props.p || 0);
  }};
  flex-direction: ${({ direction }) => direction || ''};
  align-items: ${({ alignItems }) => alignItems || ''};
  align-content: ${({ alignContent }) => alignContent || ''};
  justify-content: ${({ justify }) => justify || ''};
  flex-wrap: ${({ wrap }) => wrap || ''};
  text-align: ${({ textAlign }) => textAlign || ''};
  display: ${({ display }) => display || 'block'};
  ${props => props.size ? `
    width: 100%;
    max-width: ${props.theme.breakPoints[props.size]};
  ` : ''}
  border-radius: ${props => props.borderRadius ? props.theme.borderRadius : 0}px;
`;

Box.displayName = 'RS-Box';