export const convertHexToRgba = (phex: string, opacity: number): string => {
  let hex = phex.replace('#', '');
  if (hex.length === 3) {
    hex += hex;
  }
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  const result = `rgba(${r},${g},${b},${opacity / 100})`;
  return result;
};

export const rgbToHex = (color: string) => {
  const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
  const hex = `#${((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1)}`;
  return hex;
}


const colors = ['yellow', 'green', 'cyan', 'violet', 'red', 'orange'];

export const getRandomColors = () => {
  const mainIndex = Math.floor(Math.random() * colors.length);
  const left = mainIndex > 0 ? mainIndex - 1 : colors.length - 1;
  const right = mainIndex === colors.length - 1 ? 0 : mainIndex + 1;
  const secondaries = [left, right];
  const randomSec = Math.floor(Math.random() * 2);
  const secondarayIndex = secondaries[randomSec];
  return {
    main: colors[mainIndex],
    secondary: colors[secondarayIndex]
  }
}