import { Section, Content, Display, Box } from 'components';
import styled, { ThemeContext } from 'styled-components';
import img from 'assets/psychedelic-background.jpeg'
import { useContext } from 'react';

const Wrapper = styled(Box)`
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow: auto;

  > * {
    scroll-snap-align: start;
  }
`

const TextWrapper = styled(Box)`
  color: transparent;
  -webkit-background-clip: text;
  background-image: url(${img});
  /* background-size: cover; */
`;

const WorkInProgress = styled(Section)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  ${Content} {
    width: 100%;
    max-width: 960px;
  }
`

export function Home() {
  const { typography: { display } } = useContext(ThemeContext)
  return (
    <Wrapper>
      <WorkInProgress>
        <Content>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="https://www.w3.org/1999/xlink"
            width='100%'
            height="100%"
            viewBox='0 0 1920 550'
          >
            <defs>
              <pattern id="p1" patternUnits="userSpaceOnUse" width="626" height="417">
                <image href={img} width="626" height="417" />
              </pattern>
            </defs>
            <text
              x="0%"
              y="220"
              fontSize="220"
              fill={`url(#p1)`}
              fontFamily={display.font}
              fontWeight={display.fontWeight}
            >
              <tspan x="0">Working on this</tspan>
              <tspan x="0" dy="1.2em">since 2009...</tspan>
            </text>
          </svg>
          <p>No really, I just need some time, to work on it</p>
        </Content>
      </WorkInProgress>
    </Wrapper>
  )
}