import { forwardRef, useEffect, useRef, useState } from 'react';
import { Dropdown, DropdownItem, DropdownItemProps } from '../Dropdown';
import { TextField, TextFieldProps } from '../TextField';

function AutocompleteOption (props: DropdownItemProps & {children: any, onClick: (v: any) => void}) {
  const ref = useRef<HTMLLIElement>(null);
  const { selected } = props;
  useEffect(() => {
    if(selected) {
      if(ref.current?.parentElement?.parentElement) {
        const wrapper = ref.current.parentElement.parentElement;
        const elementTop = ref.current.offsetTop;
        const elementBottom = ref.current.offsetTop + ref.current.clientHeight;
        const scrollBottom = wrapper.clientHeight + wrapper.scrollTop;
        if(scrollBottom < elementBottom) {
          wrapper.scrollTop = elementBottom - wrapper.clientHeight;
        } else if(elementTop < scrollBottom - wrapper.clientHeight) {
          wrapper.scrollTop = ref.current.offsetTop;
        }
      } 
    }
  }, [selected]);

  return <DropdownItem {...props} ref={ref} role="button" tabIndex={-1} />
}

type Option = {
  value: string | number;
  content?: string | number;
  label?: any; 
}

interface AutocompletePorps extends TextFieldProps {
  options: Option[]
}

export const Autocomplete = forwardRef((props: AutocompletePorps, ref) => {
  const { options, onChange, value: propsValue, ...rest } = props;
  const [value, setValue] = useState<string | number>(propsValue ?? '');
  // const [target, setTarget] = useState<HTMLElement | null>(null);
  // const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  // const [focusOption, setFocusOption] = useState<Option | null>(null);
  // const [isFocus, setIsFocus] = useState(false);
  // const [filteredOptions, setFilteredOptions] = useState(options);


  const onCloseHandler = () => {
    // console.log(value);
    // if(value === '') {
    //   clear();
    // } else {
    //   setValue(selectedOption?.value ?? '');
    // }
    // setIsFocus(false);
  }


  const onChangeHandler = (event: any, value: string | number) => {
    const clonedEvent = event;
    Object.defineProperty(clonedEvent, 'target', {
      writable: true,
      value: { value },
    });
    onChange && onChange(clonedEvent);
  }

  // const showDropdown = showOptions ? target : null;
  const showDropdown = true;

  // useEffect(() => {
  //   const newOptions = options.filter((option) => {
  //     if(option.content) {
  //       return `${option.content}`.toLowerCase().indexOf(`${value}`.toLowerCase()) > -1;  
  //     }
  //     return `${option.value}`.toLowerCase().indexOf(`${value}`.toLowerCase()) > -1;
  //   });
  //   setFilteredOptions(newOptions);
  // }, [options, value]);

  // useEffect(() => {
  //   const option = options.find(option => option.value === propsValue);
  //   setSelectedOption(option ?? null);
  //   setValue(propsValue ?? '');
  // }, [propsValue, options]);

  return (
    <TextField
      {...rest}
      value={value}
      wrapperRef={(node) => {
        // if(!target && node) {
        //   setTarget(node);
        // }
      }}
      onFocus={() => {
        // setIsFocus(true);
      }}
      onChange={(event) => {
        const {value} = event.currentTarget;
        setValue(value);
        // setIsFocus(true);
        if(value === '') {
          // setFocusOption(null);
          // onChangeHandler(event, '')
        }
      }}
      onKeyDown={(event) => {
        const { key } = event;
        if(key === 'ArrowDown') {
          // event.preventDefault();
          // const currentFocusedOption = filteredOptions.findIndex((option) => option === focusOption) ?? 0;
          // const nextOption = currentFocusedOption < filteredOptions.length - 1 ? currentFocusedOption + 1 : currentFocusedOption;
          // setFocusOption(filteredOptions[nextOption]);
          return;
        }

        if(key === 'ArrowUp') {
          // event.preventDefault();
          // const currentFocusedOption = filteredOptions.findIndex((option) => option === focusOption) ?? 0;
          // const nextOption = currentFocusedOption > 0 ? currentFocusedOption - 1 : 0;
          // setFocusOption(filteredOptions[nextOption]);
          return;
        }

        if(key === 'Tab') {
          // setValue(selectedOption?.value ?? '');
          // setIsFocus(false);
        }

        if(key === 'Enter' || key === 'Escape') {
          // if(!focusOption?.value) {
          //   return;
          // }
          // event.preventDefault();
          // target && target.focus();
          // setIsFocus(false);
          // setSelectedOption(focusOption);
          // setValue(focusOption?.value ?? '');
          // onChangeHandler(event, focusOption?.value ?? selectedOption?.value ?? '');
        }
      }}
    >
      <Dropdown 
        // target={showDropdown}
        onClose={onCloseHandler}
      >
        {
          options
            .filter(() => {
              return true;
            })
            .map(({value, label}, index) => (
              <AutocompleteOption
                key={`${value}-${index}`}
                // selected={option === focusOption}
                onClick={(event: any) => {
                  const clonedEvent = event;
                  event.preventDefault();
                  console.log('click');
                  Object.defineProperty(clonedEvent, 'target', {
                    writable: true,
                    value: { value },
                  });
                  // setSelectedOption(option);
                  // setValue(option?.value ?? '');
                  // onChangeHandler(event, option.value ?? '');
                  // target?.getElementsByTagName('input')[0]?.focus();
                  onCloseHandler();
                }}
              >
                {label ?? value}
              </AutocompleteOption>
            ))
        }
        {/* {filteredOptions.map((option, index) => {
          const {value, label} = option;
          return (
          <AutocompleteOption
            key={`${value}-${index}`}
            // selected={option === focusOption}
            onClick={(event: any) => {
              const clonedEvent = event;
              event.preventDefault();
              console.log('click');
              Object.defineProperty(clonedEvent, 'target', {
                writable: true,
                value: { value },
              });
              // setSelectedOption(option);
              setValue(option?.value ?? '');
              onChangeHandler(event, option.value ?? '');
              // target?.getElementsByTagName('input')[0]?.focus();
              onCloseHandler();
            }}
          >
            {label ?? value}
          </AutocompleteOption>
        )})} */}
      </Dropdown>
    </TextField>
  ) 
})