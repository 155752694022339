const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);
const getRandomColorValue = (min: number = 0, max: number = 255) => {
  return clamp(Math.ceil(Math.random() * max), min, max)
};

export const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const colors = [...weekDays].map((label) => ({
  label,
  color: `rgb(${getRandomColorValue()}, ${getRandomColorValue()}, ${getRandomColorValue()})`,
  // value: 2,
  value: Math.ceil(Math.random() * 10),
}))