import styled from 'styled-components';

export interface TypographyProps {
  as?: string;
  fontWeight?: number | 'bold' | 'lighter' | 'normal';
  textTransform?: 'capitalize' | 'uppercase' | 'lowercase' | 'initial' | 'inherit';
  textAlign?: 'center' | 'left' | 'right';
  paddingBottom?: boolean
  block?: boolean
}

const Base = styled.p<TypographyProps>`
  position: relative;
  ${props => props.block ? 'display: block;' : ''}
  ${props => props.fontWeight ? `font-weight: ${props.fontWeight};` : ''}
  ${props => props.textTransform ? `text-transform: ${props.textTransform};` : ''}
  padding: 0 0 ${(props) => props.paddingBottom ? '.75rem' : '0'} 0;
  margin: 0;
`;

export const Typography = styled(Base)`
  font-family: ${({ theme }) => theme.typography.body.font};
  font-weight: ${({ theme, fontWeight }) => fontWeight || theme.typography.body.fontWeight};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  letter-spacing: ${({ theme }) => theme.typography.body.letterSpacing};
  line-height:  ${({ theme }) => theme.typography.body.lineHeight};
  text-align: ${props => props.textAlign};
  &:is(h1) {
    font-size: ${({ theme }) => theme.typography.headline.fontSize};
    font-weight: ${({ theme }) => theme.typography.headline.fontWeight};
    line-height: ${({ theme }) => theme.typography.headline.lineHeight};
  }
  &:is(h1:first-child) {
    margin-top: 0;
  }
  &:is(h2) {
    font-size: ${({ theme }) => theme.typography.subHeading.fontSize};
    font-weight: ${({ theme }) => theme.typography.subHeading.fontWeight};
    line-height: ${({ theme }) => theme.typography.subHeading.lineHeight};
  }
  &:is(h3) {
    font-size: ${({ theme }) => theme.typography.h3.fontSize};
    font-weight: ${({ theme }) => theme.typography.h3.fontWeight};
    line-height: ${({ theme }) => theme.typography.h3.lineHeight};
  }
  &:is(h4) {
    font-size: ${({ theme }) => theme.typography.h4.fontSize};
    font-weight: ${({ theme }) => theme.typography.h4.fontWeight};
    line-height: ${({ theme }) => theme.typography.h4.lineHeight};
  }
  &:is(h5) {
    font-size: ${({ theme }) => theme.typography.h5.fontSize};
    font-weight: ${({ theme }) => theme.typography.h5.fontWeight};
    line-height: ${({ theme }) => theme.typography.h5.lineHeight};
  }
  &:is(h6) {
    font-size: ${({ theme }) => theme.typography.h6.fontSize};
    font-weight: ${({ theme }) => theme.typography.h6.fontWeight};
    line-height: ${({ theme }) => theme.typography.h6.lineHeight};
  }
  &:is(small) {
    font-size: ${({ theme }) => theme.typography.small.fontSize};
    font-weight: ${({ theme }) => theme.typography.small.fontWeight};
    line-height: ${({ theme }) => theme.typography.small.lineHeight};
    display: ${props => props.block ? 'block' : 'inline-block'};
  }
`;

export const Display = styled(Base)`
  @media (max-width: ${props => props.theme.breakPoints.sm}) { 
    font-size: ${({ theme }) => theme.typography.headline.fontSize};
  }
  /* default */
  font-family: ${({ theme }) => theme.typography.display.font};
  font-size: ${({ theme }) => theme.typography.display.fontSize};
  font-weight: ${({ theme }) => theme.typography.display.fontWeight};
  letter-spacing: ${({ theme }) => theme.typography.display.letterSpacing};
  line-height:  ${({ theme }) => theme.typography.display.lineHeight};
  text-transform: uppercase;
  text-align: ${props => props.textAlign};
`;

Display.defaultProps = {
  as: "h1"
}

export const Subtitle1 = styled(Base)`
  font-family: ${({ theme }) => theme.typography.subTitle1.font};
  font-weight: ${({ theme }) => theme.typography.subTitle1.fontWeight};
  letter-spacing: ${({ theme }) => theme.typography.subTitle1.letterSpacing};
  line-height:  ${({ theme }) => theme.typography.subTitle1.lineHeight};
  text-align: ${props => props.textAlign};
`;

Subtitle1.defaultProps = {
  as: "h3"
}

export const Subtitle2 = styled(Base)`
  font-family: ${({ theme }) => theme.typography.subTitle2.font};
  font-weight: ${({ theme }) => theme.typography.subTitle2.fontWeight};
  letter-spacing: ${({ theme }) => theme.typography.subTitle2.letterSpacing};
  line-height:  ${({ theme }) => theme.typography.subTitle2.lineHeight};
  text-align: ${props => props.textAlign};
`;

Subtitle2.defaultProps = {
  as: "h4"
}

Display.defaultProps = {
  as: "h1",
}

Typography.defaultProps = {
  paddingBottom: false
}

export const Blockquote = styled(Base)`
  font-family: ${({ theme }) => theme.typography.blockquote.font};
  font-weight: ${({ theme }) => theme.typography.blockquote.fontWeight};
  letter-spacing: ${({ theme }) => theme.typography.blockquote.letterSpacing};
  line-height:  ${({ theme }) => theme.typography.blockquote.lineHeight};
  text-align: ${props => props.textAlign};
  font-size: ${({ theme }) => theme.typography.blockquote.fontSize};
  padding: 2rem 0 2rem 2.5rem;
  &:before {
    content: "“";
    font-family: Proxima Nova,Helvetica,Arial,sans-serif;
    font-weight: 700;
    position: absolute;
    color: ${props => props.theme.palette.primary.main};
    font-size: 4rem;
    left: 0;
    top: .5rem;
    padding: 15px 0 0;
  }
`;

Blockquote.defaultProps = {
  as: 'blockquote'
}
