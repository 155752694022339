import styled from 'styled-components';
import { getSize, ButtonBaseProps } from '../Button';

// TODO: needs work;
interface IconButtonProps {
  disableHover?: boolean;
  size?: ButtonBaseProps['size']
}

export const IconButton = styled.button<IconButtonProps>`
  box-sizing: border-box;
  white-space: nowrap;
  line-height: 1;
  padding: 0.5rem;
  font-size: 1em;
  border-radius: ${props => props.theme.borderRadius}px;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: border 150ms ease-in;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.palette.background.contrastText};

  &:focus {
    outline: 1px solid ${props => props.theme.palette.outline};
  }

  &:focus,
  &:hover {
    transition: 100ms ease-in-out all;
    background-color: ${props => props.theme.palette.divider};
  }

  svg {
    width: 100%;
    height: ${props => getSize(props.size || 'md')}rem;
    * {
      fill: currentColor;
    }
  }
`;

IconButton.defaultProps = {
  size: 'md'
}