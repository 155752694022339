import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${({theme}) => {
    return theme.palette.primary.main;
  }};
`;
export interface TestProps {
  content?: string;
}
export function Test(props: TestProps) {
  return (<Wrapper>{props.content}</Wrapper>)
}

export const defaultProps: TestProps = {
  content: 'https://storybook.js.org/tutorials/design-systems-for-developers/react/en/distribute/'
}

Test.defaultProps = { ...defaultProps }