import styled, { BreakPoints } from "styled-components";
import { Paper, PaperProps } from '../Paper';
import { Box } from '../Box';
import { Backdrop } from '../Backdrop';
import { Spinner } from '../Spinner';
import { Typography } from '../Typography';

export interface DialogProps extends PaperProps {
  size?: keyof BreakPoints;
  children: any;
  loading?: boolean;
}

export const DialogTitle = styled(Typography)`
  padding-left: ${props => props.theme.spacing.space(1.5)};
  padding-right: ${props => props.theme.spacing.space(1.5)};
  padding-top: ${props => props.theme.spacing.space(1)};
  padding-bottom: ${props => props.theme.spacing.space(1)};

  & > small {
   opacity: 0.7;
  }
`;

DialogTitle.defaultProps = {
  as: 'h2',
  textTransform: 'uppercase'
}

export const DialogContent = styled(Box)`
  flex: 1 1 auto;
  overflow: auto;
  &:not(:first-child) {
    padding-top: 0;
  }
`;


DialogContent.defaultProps = {
  px: 1.5,
  py: 1
}

export const DialogActions = styled(Box)``;

DialogActions.defaultProps = {
  p: 1,
  display: 'flex',
  justify: 'flex-end',
  alignItems: 'center'
}

export const DialogWrapper = styled(Paper)<Omit<DialogProps, 'loading' | 'children'>>`
  position: relative;
  width: calc(100vw - ${props => props.theme.pageGutter});
  max-height: calc(100vh - ${props => props.theme.pageGutter});
  max-width: ${props => props.size ? props.theme.breakPoints[props.size] : ''};
  display: flex;
  flex-direction: column;
  > * {
    z-index: 0;
  }
`;

export function Dialog(props: DialogProps) {
  const {children, loading, ...rest} = props;
  return (
    <DialogWrapper {...rest}>
      {children}
      {loading && <Backdrop position="absolute" blur={0}><Spinner /></Backdrop>}
    </DialogWrapper>
  )
}

Dialog.defaultProps = {
  display: 'inline-block',
  size: 'sm',
}