import { Box, BarChart, LineChart, ScatterChart, PieChart } from 'components';
import { useFetch } from 'hooks';
import { useRef } from 'react';
import styled from 'styled-components';
import { colors, weekDays } from './data';

const Content = styled(Box)`
  min-height: 100vh;
  display: grid;
  gap: 2rem;
  width: auto;
  justify-content: center;
  grid-template-columns: repeat(2, auto);
  padding: 2rem;
`;

const url = 'https://api.openweathermap.org/data/2.5/onecall?lat=26.1061368&lon=-80.2389599&appid=28e9002d75c7d1f31516a2aea73cd3eb';

const epochToDate = (epoch: number) => {
  const d = new Date(0);
  d.setUTCSeconds(epoch);
  return d;
}

const epochWeekDays = (epoch: number) => weekDays[epochToDate(epoch).getDay()];

export function Charts() {
  const width = 600;
  const height = 600;
  const bars = [...colors];
  const points = useRef(new Array(10).fill(null).map((_, index) => ({
    label: `${index}`,
    valueX: index + 1 * Math.random() * 10,
    valueY: index + 1 * Math.random() * 10,
  })))
  const { data } = useFetch<WeatherResponse>({ url });
  // const { data: people } = useFetch<PeopleResponse>({ url: 'https://randomuser.me/api/?results=5' });
  const { data: movies } = useFetch<Movie[]>({ url: 'https://svg-fundamentals-awwwards-course.georgi-nikolov.com/api/favorite-movies' });
  const humidity = data?.daily.map((item, index) => ({
    label: epochWeekDays(item.dt),
    value: Number(item.humidity)
  }));
  const windSpeed = data?.daily.map((item, index) => ({
    label: epochWeekDays(item.dt),
    value: Number(item.wind_speed)
  }))
  const clouds = data?.daily.map((item, index) => ({
    label: epochWeekDays(item.dt),
    value: Number(item.clouds)
  }))
  const hourlyHumidity = data?.hourly.map((item, index) => ({
    label: epochToDate(item.dt).getHours().toString(),
    value: Number(item.humidity)
  }));

  return (
    <Content display="flex" alignItems="center" justify="center">
      <Box display="flex" alignItems="center" justify="center">
        <PieChart
          title="Movies"
          width={width}
          height={height}
          // values={people?.results?.map(({ name, dob }) => ({
          //   label: `${name.first} ${name.first}`,
          //   value: dob.age
          // }))}
          values={movies?.map((movie) => ({
            label: movie.name,
            value: movie.count,
            color: movie.color
          }))}
        />
      </Box>
      <Box display="flex" alignItems="center" justify="center">
        <PieChart
          title="Colors"
          width={width}
          height={height}
          values={colors}
        />
      </Box>
      <Box display="flex" alignItems="center" justify="center">
        <ScatterChart
          title="Random Numbers"
          width={width}
          height={height}
          points={points.current}
          stepsY={10}
          stepsX={10}
          markerSize={6}
        />
      </Box>
      <Box display="flex" alignItems="center" justify="center">
        <LineChart
          title="Hourly Humidity"
          width={width}
          height={height}
          points={hourlyHumidity}
          showLabels
          steps={5}
          showMarker
        />
      </Box>
      <Box display="flex" alignItems="center" justify="center">
        <LineChart
          title="Clouds"
          width={width}
          height={height}
          points={clouds}
          showLabels
          steps={5}
          showMarker
        />
      </Box>
      <Box display="flex" alignItems="center" justify="center">
        <BarChart
          title="Humidity"
          width={width}
          height={height}
          bars={humidity}
          showLabels
          steps={3}
        />
      </Box>
      <Box display="flex" alignItems="center" justify="center">
        <BarChart
          title="Wind Speed"
          width={width}
          height={height}
          bars={windSpeed}
          showLabels
          showAverage
          steps={4}
        />
      </Box>
      <Box display="flex" alignItems="center" justify="center">
        <BarChart
          title="Clouds"
          width={width}
          height={height}
          bars={clouds}
          showLabels
          steps={3}
        />
      </Box>
      <Box display="flex" alignItems="center" justify="center">
        <BarChart
          title="Days"
          width={width}
          height={height}
          bars={bars}
          showLabels
          showAverage
          steps={4}
        />
      </Box>
    </Content>
  )
}