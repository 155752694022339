import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { SVG, Line } from './Svg';

interface Point {
  value: number;
  label: string;
  color?: string;
}

interface ScatterChartProps {
  title?: string;
  width: number;
  height: number;
  values?: Point[];
}

const descArc = ({
  centerX = 0,
  centerY = 0,
  radius = 0,
  startAngle = 0,
  endAngle = 0,
}) => {
  const arcLength = endAngle - startAngle;
  const arcMiddle = endAngle - arcLength / 2;
  const startX = centerX + Math.cos(startAngle) * radius;
  const startY = centerY + Math.sin(startAngle) * radius;
  const middleX = centerX + Math.cos(arcMiddle) * radius;
  const middleY = centerY + Math.sin(arcMiddle) * radius;
  const endX = centerX + Math.cos(endAngle) * radius;
  const endY = centerY + Math.sin(endAngle) * radius;
  const angle = 0;
  const largeArcFlag = endAngle - startAngle <= Math.PI ? 0 : 1;
  const sweepFlag = 1;

  const definition = `
    M ${centerX}, ${centerY}
    L ${startX}, ${startY}
    A ${radius}, ${radius}, ${angle}, ${largeArcFlag}, ${sweepFlag}, ${endX}, ${endY}
    z
  `
  return {
    middleX,
    middleY,
    endX,
    endY,
    definition,
  }
}

interface GetValuesArgs {
  values: Point[];
  centerX?: number;
  centerY?: number;
  radius?: number;
}

const getValues = ({
  values = [],
  centerX = 0,
  centerY = 0,
  radius = 1,
}: GetValuesArgs) => {
  const totalValues = values.reduce((acc, { value }) => acc += value, 0);
  let startAngle = 0;

  return values.map((point) => {
    const { value } = point;
    const percentage = value / totalValues;
    const angleStep = percentage * Math.PI * 2;
    const endAngle = startAngle + angleStep;
    const { endX, endY, middleX, middleY, definition } = descArc({
      centerX,
      centerY,
      radius,
      startAngle,
      endAngle,
    })
    startAngle += angleStep;
    return {
      ...point,
      definition,
      middleX,
      middleY,
      endX,
      endY,
    }
  });
}

interface Pie extends Point {
  definition: string;
  endX: number;
  endY: number;
  middleX: number;
  middleY: number;
}

export function PieChart(props: ScatterChartProps) {
  const { palette } = useContext(ThemeContext);
  const [pie, setPie] = useState<Pie[]>([]);
  const [labels, setLabels] = useState<Pie[]>([]);
  const {
    title,
    width = 0,
    height = 0,
    values = [],
  } = props;

  useEffect(() => {
    const padding = {
      left: 50,
      right: 40,
      top: 150,
      bottom: 50,
    }
    const chartBox = {
      x: padding.left,
      y: padding.top,
      width: width - padding.left - padding.right,
      height: height - padding.top - padding.bottom,
    }
    const radius = Math.min(chartBox.width, chartBox.height) * .5;
    const centerX = chartBox.width / 2 + padding.left;
    const centerY = chartBox.height / 2 + padding.top;
    const pie = getValues({
      values,
      centerX,
      centerY,
      radius
    });
    const labels = getValues({
      values,
      centerX,
      centerY,
      radius: radius * .8
    });
    setPie(pie);
    setLabels(labels);
  }, [height, values, width]);
  // 
  return (
    <SVG width={width} height={height}>
      {title && <text x={20} y={40} fontSize={20}>{title}</text>}
      {pie.map(({ color, definition }, index) => {
        return <path key={index} d={definition} fill={color ?? 'none'} stroke={!color ? palette.primary.main : 'none'} />
      })}
      {labels.map(({ value, middleX, middleY }, index) => {
        return (
          <text
            key={index}
            x={middleX}
            y={middleY}
            r={2}
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {value}
          </text>
        )
      })}
    </SVG>
  )
}