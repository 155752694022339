import { Box, Paper, Typography, Divider, IconButton, Button } from 'components';
import styled from 'styled-components';
import { numberToDollars, numberToPercentage } from 'helpers';
import React, { useEffect, useState } from 'react';
import { ReactComponent as EditIcon } from '../../icons/EditIcon.svg';

interface Title {
  type: 'title';
  title: string;
}

interface Item {
  title?: string;
  name: string;
  value: number;
  uom?: string;
  helperText?: string;
}

type Element = Item | Title

export interface DataBoxProps {
  showSeverity?: boolean;
  open?: boolean;
  openClickHandler?: () => void;
  editClickHandler?: () => void;
  title: string;
  items: Element[],
  total: {
    title: string;
    value: number;
    uom?: string;
    minTarget?: number;
  }[];
}

const Total = styled(Box) <{ severity: string; }>`
  > *:not(small) {
    color: ${({ severity, theme: { palette } }) => {
    if (severity === 'error') {
      return palette.error.main;
    }
    if (severity === 'success') {
      return palette.success.main;
    }
    if (severity === 'warning') {
      return palette.warning.main;
    }
    return '';
  }};
  }
`;

const ItemTitle = styled(Box)``;

const ItemValue = styled(Typography) <{ uom?: string }>`
  flex: 0 0 auto;
  display: flex;
`;

ItemValue.defaultProps = {
  textAlign: 'right'
}

const Item = styled(Box)``;

Item.defaultProps = {}

const Items = styled(Box)`
  > ${Item}:first-child {
    border-top: 1px solid ${props => props.theme.palette.divider};
  }
  > *:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.palette.divider};
  }
`;


const DataBoxWrapper = styled(Paper)`
  summary {
    cursor: pointer;
    &:marker {
      display: none;
    }
  }
  h1 {
    font-size: 2em;
  }
`;

const getValue = (value: number, uom?: string) => {
  switch (uom) {
    case '%':
      return numberToPercentage(value / 100);
    case '$':
      return numberToDollars(value);
    default:
      return value;
  }
}

const getSeverity = (val: number, target = 0) => {
  if (val < target) return 'error';
  return 'success';
}

export function DataBox(props: DataBoxProps) {
  const {
    title,
    items,
    total,
    open: isOpen,
    openClickHandler,
    editClickHandler,
    showSeverity
  } = props;
  const [open, setOpen] = useState(true);
  const toggleOpen = () => {
    openClickHandler && openClickHandler();
    !openClickHandler && setOpen(prev => !prev);
  }

  useEffect(() => {
    if (isOpen === true || isOpen === false) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  return (
    <DataBoxWrapper variant="outlined">
      <Box p={1}>
        <Box display="flex" justify="space-between">
          <Box role="button" onClick={() => {
            toggleOpen()
          }}>
            <Typography as="h1" paddingBottom>{title}</Typography>
          </Box>
          {editClickHandler && (
            <Box>
              <IconButton
                onClick={() => {
                  editClickHandler && editClickHandler()
                }}
                size="xs"
              >
                <EditIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
      {open && (
        <Items>
          {items.map((args, index) => {
            if((args as any)?.type === 'title') {
              return (
                <Item key={`${index}-${args.title}`} p={1}>
                  <Typography as="h3">{args.title}</Typography>
                </Item>
              )
            }
            const { name, value, uom, helperText, title: itemTitle } = args as Item;
            return (
              <Item key={name} p={1}>
                {itemTitle && (
                  <Typography as="h3" paddingBottom>{itemTitle}</Typography>
                )}
                <Box display='flex' alignItems="center" justify="space-between">
                  <ItemTitle>{name}</ItemTitle>
                  <ItemValue as="h3" uom={uom}>
                    {getValue(value, uom)}
                  </ItemValue>
                </Box>
                {helperText && (
                  <Typography as="small">{helperText}</Typography>
                )}
              </Item>
            )
          })}
        </Items>
      )}
      {total.map(({ title: totalTitle, value, uom = '$', minTarget = 0 }, index) => (
        <React.Fragment key={`${title}-${index}`}>
          <Divider spacing="none" />
          <Total p={1} severity={!showSeverity ? 'idle' : getSeverity(value ?? 0, minTarget)}>
            {totalTitle && (
              <Typography
                as="small"
                textTransform="capitalize"
                block
                textAlign='right'
              >
                {totalTitle}
              </Typography>
            )}
            <Typography as="h2" textAlign='right'>
              {`${getValue(value ?? 0, uom)}`}
            </Typography>
          </Total>
        </React.Fragment>
      ))}
    </DataBoxWrapper>
  )
}

DataBox.defultProps = {
  open: true,
  showSeverity: false
}