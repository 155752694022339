import { createContext } from "react";
// https://www.w3schools.com/charsets/ref_emoji.asp

const figures = [
  ['👦', '👧'],
  ['💓', '💗'],
  ['💵', '💴'],
  ['📀', '💿'],
  ['📫', '📪'],
  ['📷', '📸'],
]

export enum STATUS {
  PLAYING = 'PLAYING',
  OVER = 'OVER',
  START = 'START',
  LEADERBOARD = 'LEADERBOARD',
}

export interface InitialState {
  figure: string[];
  speed: number;
  maxScore: number;
  sceneStarted?: Date;
  score: number;
  lives: number;
  level: number;
  complexity: number;
  maxComplexity: number;
  correctDot: {
    col: number;
    row: number;
  };
  dots: Array<Array<string>>;
  status: STATUS
}

export enum ACTION {
  RESET = 'RESET',
  START = 'START',
  END = 'END',
  NEXT = 'NEXT',
  SELECT = 'SELECT',
  LEADERBOARD = 'LEADERBOARD'
}

interface START {
  type: ACTION.START;
}

interface RESET {
  type: ACTION.RESET;
}

interface END {
  type: ACTION.END;
}

interface SELECT {
  type: ACTION.SELECT;
  payload: InitialState['correctDot'];
}

interface NEXT {
  type: ACTION.NEXT;
}
interface LEADERBOARD {
  type: ACTION.LEADERBOARD;
}

export type ACTIONS = START | RESET | SELECT | NEXT | END | LEADERBOARD;

const createMatrix = (step: number) => Array(step)
  .fill(null)
  .map((_, col) => Array(step).fill(0).map((_, row) => `${col},${row}`));

const setNextScene = (state: InitialState): InitialState => {
  // const availableColors = colors.filter(c => c !== state.color);
  const { complexity, maxComplexity } = state;
  const level = complexity === maxComplexity ? state.level + 1 : state.level;
  const nextComplexity = complexity === maxComplexity ? 2 : complexity + 1;

  const dots = createMatrix(nextComplexity);
  const correctDot = {
    col: Math.floor(Math.random() * nextComplexity),
    row: Math.floor(Math.random() * nextComplexity)
  };
  const figure = figures[Math.floor(Math.random() * figures.length)]
  return {
    ...state,
    maxScore: getWinningLeaderboardScore(),
    sceneStarted: new Date(),
    level,
    complexity: nextComplexity,
    dots,
    correctDot,
    figure
  }
}

const dbName = 'leaderboard';

export const getLeaderboard = (): number[] => {
  const maxScore = localStorage.getItem(dbName);
  try {
    const leaderboard: number[] = (JSON.parse(maxScore ?? '[0]') ?? []) ?? [];
    return leaderboard.sort((a: number, b: number) => b - a);
  } catch (err) {
    console.error(err);
    return []
  }
}

const getWinningLeaderboardScore = () => getLeaderboard()[0]

const updateLeaderboard = (newScore: number) => {
  console.log(newScore);
  const maxScore = getLeaderboard();
  const newLeaderboard = [...maxScore, newScore].sort((a, b) => a - b).filter(v => v);
  localStorage.setItem(dbName, JSON.stringify(newLeaderboard));
}

export const initialState: InitialState = {
  maxScore: 0,
  status: STATUS.START,
  figure: figures[Math.floor(Math.random() * figures.length)],
  lives: 3,
  speed: 5,
  score: 0,
  level: 0,
  complexity: 1,
  maxComplexity: Infinity + 1,
  correctDot: {
    col: 0,
    row: 0
  },
  dots: createMatrix(0),
}

const getBonusPoints = (secondsPassed: number, speed: number, level: number) => {
  if (secondsPassed >= speed) return 0;

  return Math.round(speed / secondsPassed);
}

const gameState = (state: InitialState = initialState, action: ACTIONS) => {
  switch (action.type) {
    case ACTION.RESET:
      return {
        ...initialState,
        status: STATUS.START
      }
    case ACTION.LEADERBOARD:
      return {
        ...initialState,
        status: STATUS.LEADERBOARD
      }
    case ACTION.START:
      return {
        ...initialState,
        ...setNextScene(initialState),
        status: STATUS.PLAYING
      }
    case ACTION.SELECT: {
      const isCorrect = JSON.stringify(state.correctDot) === JSON.stringify(action.payload);
      const score = isCorrect ? 5 : 0;
      const lives = isCorrect ? state.lives : state.lives - 1;
      const now = new Date();
      const secondsPassed = state.sceneStarted ? Math.abs(now.getTime() - state.sceneStarted.getTime()) / 1000 : 0;

      return {
        ...initialState,
        ...setNextScene(state),
        lives,
        score: state.score + score + (
          isCorrect ? getBonusPoints(secondsPassed, state.speed, state.level) : 0
        ),
      }
    }
    case ACTION.NEXT: {
      const lives = state.lives - 1;
      return {
        ...initialState,
        ...setNextScene(state),
        lives
      }
    }
    default:
      return state;
  }
}

export const reducer = (state: InitialState = initialState, action: ACTIONS) => {
  const result = { ...gameState(state, action), maxScore: getWinningLeaderboardScore() };
  if (result.lives === 0) {
    updateLeaderboard(result.score);
    return {
      ...result,
      status: STATUS.OVER
    }
  }
  return result;
}



interface GameContextArgs {
  dispatch: React.Dispatch<ACTIONS>,
  state: InitialState
}

export const GameContext = createContext<GameContextArgs>({
  dispatch: () => { },
  state: initialState
})