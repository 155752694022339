import { Modal, Dialog, DialogTitle, Typography, DialogContent, Grid, GridItem, TextField, DialogActions, Button, Box } from "components";
import { isBiggerThanCero, isNotEmpty, numberToDollars } from "helpers";
import { useCallback, useContext, useEffect, useState } from "react"
import { useForm, Controller } from "react-hook-form";
import { HomeCountext } from "./Context"
import { DataBox } from "./DataBox";
import { ACTION } from "./reducer";

interface DialogProps {
  open: boolean;
  onClose: () => void;
}

function DialogContainer(props: DialogProps) {
  const {
    open,
    onClose,
  } = props;
  const {state, dispatch} = useContext(HomeCountext);
  const {
    tax,
    insurance,
    gas,
    electric,
    water,
    sewer,
    garbage,
    lawn,
    snow,
    managment,
    vacancy,
    maintance,
  } = state;

  const fields = useCallback((): {
    label: string,
    name: string,
    before: string,
    validate?: (v: string | number) => boolean,
    value: number | string,
    type?: "number" | "text" | "password" | undefined
  }[] => [
    {
      label: 'Tax',
      name: 'tax',
      before: '$',
      validate: isBiggerThanCero,
      value: tax
    },
    {
      label: 'Insurance',
      name: 'insurance',
      before: '$',
      validate: isBiggerThanCero,
      value: insurance
    },
    {
      label: 'Gas',
      name: 'gas',
      before: '$',
      value: gas
    },
    {
      label: 'Electric',
      name: 'electric',
      before: '$',
      value: electric
    },
    {
      label: 'Water',
      name: 'water',
      before: '$',
      value: water
    },
    {
      label: 'Sewer',
      name: 'sewer',
      before: '$',
      value: sewer
    },
    {
      label: 'Garbage',
      name: 'garbage',
      before: '$',
      value: garbage
    },
    {
      label: 'lawn',
      name: 'lawn',
      before: '$',
      value: lawn
    },
    {
      label: 'Snow',
      name: 'snow',
      before: '$',
      value: snow
    },
    {
      label: 'Managment',
      name: 'managment',
      before: '%',
      value: managment
    },
    {
      label: 'Vacancy',
      name: 'vacancy',
      before: '%',
      value: vacancy
    },
    {
      label: 'Maintance',
      name: 'maintance',
      before: '%',
      value: maintance
    }
  ], [electric, garbage, gas, insurance, lawn, maintance, managment, sewer, snow, tax, vacancy, water])

  const getDefaultValues = useCallback(() => {
    let fieldsObj: {[key: string]: string | number} = {};

    fields().forEach(({name, value}) => {
      fieldsObj[name] = value
    })
    
    return fieldsObj
  }, [fields])
  const {
    control,
    handleSubmit,
    reset,
    formState: {
      isValid,
    },
  } = useForm({
    mode: "onChange",
    defaultValues: getDefaultValues()
  });

  useEffect(() => {
    reset(getDefaultValues())
  }, [getDefaultValues, reset, state])


  const closeHandler = () => {
    onClose();
  }
  const onSubmit = (formData: any) => {
    const payload = {
      electric: Number(formData.electric),
      garbage: Number(formData.garbage),
      gas: Number(formData.gas),
      insurance: Number(formData.insurance),
      lawn: Number(formData.lawn),
      maintance: Number(formData.maintance),
      managment: Number(formData.managment),
      sewer: Number(formData.sewer),
      snow: Number(formData.snow),
      tax: Number(formData.tax),
      vacancy: Number(formData.vacancy),
      water: Number(formData.water)
    }
    dispatch({
      type: ACTION.UPDATE_EXPENSES,
      payload
    });
    closeHandler();
  }

  const isFormValid = isValid;

  return (
    <Modal
      active={open}
      onClose={closeHandler}
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Dialog loading={false}>
          <DialogTitle>
            <Typography as="small" block>Edit</Typography>
            Mortgage
          </DialogTitle>
          <DialogContent>
            <Grid gap={1}>
              {fields().map((field) => {
                const { 
                  validate = isNotEmpty,
                  name: fieldName,
                  type = 'number',
                  label,
                  before = '$'
                } = field 
                return (
                  <GridItem key={fieldName}>
                    <Controller
                      control={control}
                      name={fieldName as any}
                      rules={{ 
                        required: true, 
                        validate
                      }}
                      render={({
                        field: { onChange, value, name },
                        fieldState: { invalid },
                      }) => {
                        return (
                          <TextField
                            type={type}
                            before={before}
                            name={name}
                            id={name}
                            label={label}
                            isValid={!invalid}
                            required
                            fullWidth
                            value={value}
                            onChange={onChange}
                          />
                        )
                      }}
                    />
                  </GridItem>
                )
              })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              disabled={!isFormValid}
            >
              <Box as="span" display="flex" alignItems="center">
                Update
              </Box>
            </Button>
            <Button onClick={closeHandler}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </form>
    </Modal>
  )
}


export function ExpensesContainer() {
  const {state} = useContext(HomeCountext);
  const [open, setOpen] = useState(false);
  const {
    tax,
    insurance,
    gas,
    electric,
    water,
    sewer,
    garbage,
    lawn,
    snow,
    managment,
    vacancy,
    maintance,
    rent,
    operatingExpenses,
  } = state;
  const managmentTotal = rent * (managment / 100);
  const vacancyTotal = rent * (vacancy / 100);
  const maintanceTotal = rent * (maintance / 100);
  const expenses = [
    {
      name: `Property Taxes (${numberToDollars(tax)})`,
      value: tax / 12,
      uom: '$'
    },
    {
      name: 'Insurance',
      value: insurance,
      uom: '$'
    },
    {
      name: 'Gas',
      value: gas,
      uom: '$'
    },
    {
      name: 'Electric',
      value: electric,
      uom: '$'
    },
    {
      name: 'Water',
      value: water,
      uom: '$'
    },
    {
      name: 'Sewer',
      value: sewer,
      uom: '$'
    },
    {
      name: 'Garbage',
      value: garbage,
      uom: '$'
    },
    {
      name: 'Lawn',
      value: lawn,
      uom: '$'
    },
    {
      name: 'Snow',
      value: snow,
      uom: '$'
    },
    {
      name: `Property Management (${managment}%)`,
      value: managmentTotal,
      uom: '$'
    },
    {
      name: `Vacancy Reserve (${vacancy}%)`,
      value: vacancyTotal,
      uom: '$'
    },
    {
      name: `Maintenance Reserve (${maintance}%)`,
      value: maintanceTotal,
      uom: '$'
    }
  ]

  const openHandler = () => {
    setOpen(true)
  }

  const closeHandler = () => {
    setOpen(false)
  }


  return (
    <>
      <DataBox
        editClickHandler={openHandler}
        title='Expenses'
        items={expenses}
        total={[
          {
            title: 'Operating Monthly Expenses',
            value: operatingExpenses,
          }
        ]}
      />
      <DialogContainer
        onClose={closeHandler}
        open={open}
      />
    </>
  )
}