import styled from 'styled-components';

const ImgWrapper = styled.div<Pick<AvatarProps, 'color'>>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${props => props.color || props.theme.palette.primary.main};
`;

const getAvatarSize = (size?: AvatarProps['size']) => {
  switch (size) {
    case 'sm':
      return 24;
    case 'md':
    default:
      return 32;
    case 'lg':
      return 56;
  }
}

const Wrapper = styled.div<AvatarProps>`
  width: ${props => getAvatarSize(props.size)}px;
  height: ${props => getAvatarSize(props.size)}px;
  display: inline-block;
  vertical-align: middle;
`;

export interface AvatarProps {
  src?: string;
  color?: string;
  size?: 'sm' | 'md' | 'lg',
}

export function Avatar(props: AvatarProps) {
  const { size, color } = props;
  return (
    <Wrapper size={size}>
      <ImgWrapper color={color} />
    </Wrapper>
  )
}

Avatar.defaultProps = {
  size: 'md'
}