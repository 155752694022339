import './App.css';
import {
  BrowserRouter,
  Route,
  Routes
} from 'react-router-dom';
import { Layout } from 'components';
import { Home, NotFound, Draw, Charts, SVGPage, GameContainer, AI, Houses, House } from 'pages';
import { ThreeScene } from 'containers';

function App() {
  return (
    <Layout className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/game" element={<GameContainer />} />
          <Route path="/three" element={<ThreeScene />} />
          <Route path="/charts" element={<Charts />} />
          <Route path="/draw" element={<Draw />} />
          <Route path="/svg" element={<SVGPage />} />
          <Route path="/ai" element={<AI />} />
          <Route path="/houses" element={<Houses />} />
          <Route path="/houses/:id" element={<House />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Layout>
  );
}

export default App;
