import styled, { DefaultTheme } from 'styled-components';

const getSpacing = (spacing: DividerProps['spacing'], theme: DefaultTheme) => {
  switch (spacing) {
    case 'small':
      return theme.spacing.space(1)
    case 'medium':
      return theme.spacing.space(2)
    case 'large':
      return theme.spacing.space(3)
    default:
      return 0;
  }
}

interface DividerProps {
  spacing?: 'none' | 'small' | 'medium' | 'large';
}

export const Divider = styled.div<DividerProps>`
  margin-top: ${(props) => getSpacing(props.spacing, props.theme)}};
  border-bottom: 1px solid ${(props): string => props.theme.palette.divider};
  margin-bottom: ${({ theme, spacing }) => getSpacing(spacing, theme)}};
`;

Divider.defaultProps = {
  spacing: 'small'
}

Divider.displayName = 'Divider';
