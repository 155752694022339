import styled from "styled-components";
import { Grid, GridProps } from '../Grid';
import { Card, CardContent } from '../Card';
import { Button } from '../Button';
import { Typography } from '../Typography';
import { Box } from '../Box';
import { IconButton } from '../IconButton';
import { Backdrop } from '../Backdrop';
import { Spinner } from '../Spinner';
import { ReactComponent as DeleteIcon } from '../../icons/DeleteIcon.svg';
import { ReactComponent as EditIcon } from '../../icons/EditIcon.svg';
import { ReactComponent as AddIcon } from '../../icons/Add.svg';

const TopActionBar = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const Caption = styled(Typography)``;
const Title = styled(Typography)`
  line-height: 1.4;
`;
const Background = styled.div<Pick<ReelProps, 'backgroundImg'>>`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-image: url(${({ backgroundImg }) => `${backgroundImg ?? ''}`});
  background-position: center;
  background-size: cover;
  opacity: .2;
  left: 0;
  top: 0;
  &:before {
    left: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    height: 50%;
    content: '';
    display: block;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  }
`;

export interface ReelProps {
  backgroundImg?: string;
  title: string;
  caption?: string;
  cta?: string;
  hideTopBar?: boolean;
  loading?: boolean;
  ctaOnClickHandler?: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  deleteClickHandler?: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  editClickHandler?: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  ctaComponent?: any;
  children?: any;
}


// TODO: ASPECT RATIO DOESNT WORK WELL ON RESIZING FIND AN OTHER SOLUTION
const ReelWrapper = styled(Card)`
  aspect-ratio: 9/16;
  min-width: 0;
  min-height: 0;
  
  ${Title},
  ${Caption} {
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  ${CardContent} {
    width: 100%;
    position: relative;
    z-index: 1;
  }
  ${Backdrop} {
    z-index: 2;
  }

  ${TopActionBar} {
    ${IconButton} {
      transition: opacity 200ms ease-in-out;
      opacity: 0;
    }
  }
  &:hover {
    ${IconButton} {
      opacity: 1;
    }
  }

  ${IconButton}:focus {
    opacity: 1;
  }
`;

export function Reel(props: ReelProps) {
  const {
    title,
    caption,
    cta,
    hideTopBar,
    children,
    loading,
    backgroundImg,
    ctaOnClickHandler = () => { },
    editClickHandler = () => { },
    deleteClickHandler = () => { }
  } = props;

  return (
    <ReelWrapper
      display="flex"
      alignItems="flex-end"
    >
      {backgroundImg && <Background backgroundImg={backgroundImg} />}
      <CardContent>
        {caption && (
          <Caption as="small" textTransform="uppercase">
            {caption}
          </Caption>
        )}
        <Title as="h2" textTransform="uppercase" paddingBottom>
          {title}
        </Title>
        {cta && (
          <Button
            size="sm"
            variant="contained"
            color="primary"
            onClick={ctaOnClickHandler}
          >
            {cta}
          </Button>
        )}
        {children}
      </CardContent>
      {!hideTopBar && !loading && (
        <TopActionBar display="flex" justify="flex-end" p={.5}>
          <IconButton
            size="xs"
            onClick={editClickHandler}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            size="xs"
            onClick={deleteClickHandler}
          >
            <DeleteIcon />
          </IconButton>
        </TopActionBar>
      )}
      {loading && <Backdrop position="absolute"><Spinner /></Backdrop>}
    </ReelWrapper>
  )
}

export interface ReelAddProps {
  addClickHandler: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export function ReelAdd(props: ReelAddProps) {
  const {
    addClickHandler = () => { }
  } = props;
  return (
    <ReelWrapper
      display="flex"
      alignItems="center"
      justify="center"
      variant="outlined"
      transparent
      borderType="dashed"
      borderWidth={5}
    >
      <IconButton size="sm" onClick={addClickHandler}>
        <AddIcon />
      </IconButton>
    </ReelWrapper>
  )
}


export interface ReelsProps extends GridProps { }

export const Reels = styled(Grid) <ReelsProps>``;

Reels.defaultProps = {
  gap: 'sm',
  breakPoints: [
    {
      minWidth: 'sm',
      columns: 2,
    },
    {
      minWidth: 'md',
      columns: 4,
    },
    {
      minWidth: 'lg',
      columns: 5,
    },
    {
      minWidth: 'xxl',
      columns: 6,
    }
  ]
}