import React, { useContext, useRef } from 'react';
import { ThemeContext } from 'styled-components';
import { SVG, Line } from './Svg';

interface Point {
  value: number;
  label: string;
  color?: string;
}

interface LineChartProps {
  title?: string;
  width: number;
  height: number;
  showLabels?: boolean;
  showAverage?: boolean;
  steps?: number;
  points?: Point[];
  showMarker?: boolean;
  markerSize?: number;
}

export function LineChart(props: LineChartProps) {
  const id = useRef(`${(new Date()).getTime()}-LineChart`)
  const { palette } = useContext(ThemeContext);
  const {
    title,
    width = 0,
    height = 0,
    points = [],
    showMarker: marker,
    markerSize = 5,
    steps
  } = props;
  const padding = {
    left: 50,
    right: 40,
    top: 150,
    bottom: 50,
  }
  const chartBox = {
    x: padding.left,
    y: padding.top,
    width: width - padding.left - padding.right,
    height: height - padding.top - padding.bottom,
  }
  const xSpacing = chartBox.width / points.length;
  const pointsValues = points.map(bar => bar.value);
  const highestValue = Math.max(...pointsValues);

  const pathDef = points.reduce((acc, point, index, arr) => {
    const x = (index * xSpacing) + padding.left;
    const normY = point.value / highestValue;
    const y = (chartBox.height - normY * chartBox.height) + padding.top;

    if (acc) {
      return `${acc} L ${x},${y}`
    }

    return `M ${x},${y}`
  }, '');

  return (
    <SVG width={width} height={height}>
      {title && <text x={20} y={40} fontSize={20}>{title}</text>}
      <defs>
        {marker && (
          <marker markerWidth={markerSize} markerHeight={markerSize} id={id.current} viewBox={`0 0 10 10`} refX={5} refY={5}>
            <circle cx={5} cy={5} r={5} fill={palette.secondary.main} />
          </marker>
        )}
      </defs>
      <path
        d={pathDef}
        stroke={palette.primary.main}
        strokeWidth={1}
        fill="none"
        markerStart={`url(#${id.current})`}
        markerMid={`url(#${id.current})`}
        markerEnd={`url(#${id.current})`}
      />
      {steps && points.length &&
        <g>
          {new Array(steps).fill(null).map((_, step, array) => {
            const spacing = chartBox.height / array.length;
            const y = spacing * step + chartBox.y;
            const value = highestValue - highestValue * (step / steps)
            return (
              <React.Fragment key={step}>
                <text
                  x={padding.left - 10}
                  y={y - 10}
                  fontSize={10}
                  textAnchor="end"
                >
                  {value.toFixed(2)}
                </text>
                <Line
                  x1={0}
                  y1={y}
                  x2={width}
                  y2={y}
                />
              </React.Fragment>
            )
          })}
        </g>
      }
    </SVG>
  )
}