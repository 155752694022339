import { useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { Box, Button } from 'components';
import { messagesAtom, MessageAtomProps } from 'atoms';
import { useSetRecoilState } from 'recoil';
import { Typography } from 'components';
import { keyframes } from 'styled-components';

const enter = keyframes`
  from {
    height: 0;
    opacity: 0;
    min-height: 0;
  }
  to {
    height: 100%;
    opacity: 1;
    min-height: 100%;
  }
`;


const MessagesWrapper = styled.div`
  position: fixed;
  top: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const MessageWrapper = styled(Box)`
  display: inline-block;
  background-color: ${props => props.theme.palette.common.white};
  color: ${props => props.theme.palette.common.black};
  border-radius: ${props => props.theme.borderRadius}px;
  overflow: hidden;
  animation-name: ${enter};
  animation-duration: 1s;
  
`

const Message = (props: MessageAtomProps) => {
  const { title, message, id } = props;
  const setMessages = useSetRecoilState(messagesAtom);
  const closeEventHandler = useCallback(() => {
    setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== id))
  }, [id, setMessages])

  useEffect(() => {
    const timer = setTimeout(() => {
      closeEventHandler();
    }, 20000);

    return () => {
      clearTimeout(timer);
    }
  }, [closeEventHandler, props, setMessages]);

  return (
    <MessageWrapper p={1}>
      <Typography as="small">{id}</Typography>
      <Typography paddingBottom={!!message}>
        <strong>{title}</strong>
      </Typography>
      {message && <Typography>{message}</Typography>}
      <Box pt={1}>
        <Button variant="contained" color="primary" onClick={closeEventHandler}>Close</Button>
      </Box>
    </MessageWrapper>
  )
}

export function MessagesContainer() {
  const messages = useRecoilValue(messagesAtom);
  return (
    <MessagesWrapper>
      {messages.map((message) => <Message key={message.id} {...message} />)}
    </MessagesWrapper>
  )
}