// TODO: work in progress
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { PopoverProps } from '../Popover';
import { List, ListItem, ListItemProps } from '../List';
import { Paper, PaperProps } from '../Paper';
import { getElementBoxInfo, getElementPosition } from '../utils/element';
import { clamp } from '../utils/number';

export interface DropdownItemProps extends ListItemProps {
  selected?: boolean;
}

export const DropdownItem = styled(ListItem)<DropdownItemProps>``;

DropdownItem.displayName = 'DropdownItem';
DropdownItem.defaultProps = {
  role: 'button'
}

interface DropdownProps extends PaperProps {
  maxHeight?: number;
  showAnchor?: PopoverProps['showAnchor']
  children: any;
  onClose(): void;
  target?: HTMLElement | null;
  anchorOrigin?: PopoverProps['anchorOrigin'];
  transformOrigin?: PopoverProps['transformOrigin'];
}

const getSide = (target: HTMLElement, container: HTMLElement, mxHeight: number) => {
  const { top: targetTop, bottom } = getElementPosition(target);
  const spaceTop = targetTop;
  const spaceBottom = window.innerHeight - bottom;
  const position = spaceTop > spaceBottom ? 'TOP' : 'BOTTOM';
  const proposedHeight = position === 'BOTTOM' ? spaceBottom : spaceTop;
  const maxHeight = mxHeight <= proposedHeight ? mxHeight : proposedHeight;

  if(position === 'TOP') {
    return {
      maxHeight,
      bottom: window.innerHeight - targetTop
    }
  }

  return {
    top: bottom,
    maxHeight 
  }
}

export function Dropdown(props: DropdownProps) {
  const {
    children,
    target,
    onClose,
    anchorOrigin,
    transformOrigin,
    showAnchor,
    maxHeight: dropDownHeight = 320,
    ...paperProps
  } = props;
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [targetEl, setTargetEl] = useState(target);
  const items = React.Children.map(children, (child) => child);
  const [style, setStyle] = useState<React.CSSProperties | undefined>();

  useEffect(() => {
    setTargetEl(target);
  }, [target]);

  useEffect(() => {
    const clickHandler = (event: MouseEvent) => {
      if(!targetEl || !dropdownRef.current) return;
      if(event.target) {
        if(dropdownRef.current?.contains(event.target as any) || targetEl.contains(event.target as any)) return;
        onClose();
        setStyle(undefined);
      }
    }
    document.addEventListener('click', clickHandler)
    
    return () => {
      document.removeEventListener('click', clickHandler)
    }
  }, [onClose, targetEl]);

  const setStyleManager = useCallback(() => {
    if(!targetEl || !dropdownRef.current) return;
    const { maxHeight, ...rest } = getSide(targetEl, dropdownRef.current, dropDownHeight);
    setStyle({
      ...rest,
      maxHeight: clamp(maxHeight, 40, maxHeight - 10),
    });
  }, [dropDownHeight, targetEl])

  useEffect(() => {
    setStyleManager();
  }, [setStyleManager, targetEl]);

  useEffect(() => {
    setStyleManager();
  }, [items.length, setStyleManager]);

  useEffect(() => {
    const scrollEventHandler = () => {
      setStyleManager();
    }

    window.addEventListener('scroll', scrollEventHandler)

    return () => {
      window.removeEventListener('scroll', scrollEventHandler);
    }
  }, [setStyleManager, targetEl]);

  if (!targetEl) return null;
  const hasBorder = paperProps.variant === 'outlined';
  
  return (
    <Paper 
      {...paperProps}
      style={{
        ...style,
        position: 'fixed',
        overflow: 'auto',
        zIndex: 1,
      }}
      ref={dropdownRef}
    >
      <List
        styles={{
          minWidth: getElementBoxInfo(targetEl).width - (hasBorder ? 2 : 0) ?? 0,
          maxWidth: getElementBoxInfo(targetEl).width - (hasBorder ? 2 : 0) ?? 0,
        }}
      >
        {items}
      </List>
    </Paper>
  );
};

Dropdown.defaultProps = {
  variant: "outlined",
  anchorOrigin: {
    horizontal: 'center',
    vertical: 'top',
  }
}


