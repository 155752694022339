import { Box, Button, ButtonGroup } from 'components';
import { useContext } from 'react';
import { ACTION, GameContext } from '../gameManager';

export function HomeScene() {
  const { dispatch } = useContext(GameContext);

  const startGameHandler = () => {
    dispatch({
      type: ACTION.START
    })
  }

  const leaderboardHandler = () => {
    dispatch({
      type: ACTION.LEADERBOARD
    })
  }

  return (
    <Box>
      <ButtonGroup>
        <Button
          onClick={startGameHandler}
          variant='contained'
          color='primary'
        >Start Game</Button>
        <Button
          onClick={leaderboardHandler}
          variant='contained'
          color='primary'
        >Leaderboard</Button>
      </ButtonGroup>
    </Box>
  )
}