import styled from 'styled-components';

export const SVG = styled.svg`
  border: ${props => props.theme.palette.divider} 1px solid;
  text {
    fill: ${props => props.theme.palette.background.contrastText};
  }
`;

SVG.defaultProps = {
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "https://www.w3.org/1999/xlink"
}

export const Line = styled.line`
  pointer-events: none;
  stroke-dasharray: 4 10;
  stroke: ${props => props.theme.palette.secondary.main};
  stroke-opacity: 0.3;
`;
