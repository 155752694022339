import { atom } from 'recoil';

export type MessageAtomProps = {
  id: string;
  title: string;
  message?: any;
  position?: 'top' | 'top-center' | 'top-bottom' | 'bottom' | 'bottom-center' | 'bottom-bottom';
}

export const messagesAtom = atom<MessageAtomProps[]>({
  key: 'globalMessages',
  default: [],
});

export const addNewMessage = (prevMessages: MessageAtomProps[], newMessage: MessageAtomProps) => [
  ...prevMessages,
  newMessage
]
