import { Alert } from "components";
import { useContext } from "react";
import { HomeCountext } from "./Context";
import { DataBox, DataBoxProps } from "./DataBox";

export function DebtPaydownSummaryContainer() {
  const {state: { totalDeductions, avgYearlyTaxSavings }} = useContext(HomeCountext);

  if(!totalDeductions.length) {
    return (
      <Alert severity="warning" variant="outlined" title="No deductions found">
        Please add some deductions years!
      </Alert>
    )
  }

  const items: DataBoxProps['items'] = [];

  totalDeductions.forEach(({
    year,
    depreciationStraightLine,
    totalInterestPaid,
    totalDeductions: itemTotalDeductions
  }) => {
    items.push({
      type: 'title',
      title: `Year ${year}`
    })
    items.push({
      name: 'Depreciation Straight Line',
      value: depreciationStraightLine,
      uom: '$'
    });
    items.push({
      name: 'Total Interest Paid',
      value: totalInterestPaid,
      uom: '$'
    });
    items.push({
      name: 'Total Deductions',
      value: itemTotalDeductions,
      uom: '$'
    });
  });
  
  return (
    <DataBox
      title={`${totalDeductions.length} Yr Debt Paydown Summary`}
      items={items}
      total={[
        {
          title: 'Total Deductions',
          value:  avgYearlyTaxSavings,
        },
        {
          title: 'Annual ROI on Tax Savings',
          value:  avgYearlyTaxSavings,
          uom: '%'
        }
      ]}
    />
  )
}