import styled, { keyframes } from 'styled-components';

const animateFace = keyframes`
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(0px, 34px);
  }
  35% {
    transform: translate(20px, 34px);
  }
  55% {
    transform: translate(-20px, 34px);
  }
  65% {
    transform: translate(0, -34px);
  }
  100% {
    transform: translate(0px, 0);
  }
`;

interface SVGProps {
  size?: 'sm' | 'md' | 'lg';
}

const getSize = (size: SVGProps['size']) => {
  switch(size) {
    case 'sm':
      return 24;
    default:
      return 36;
    case 'lg':
      return 48;
  }
}

const Head = styled.path``;
const Face = styled.g`
  animation-name: ${animateFace};
  animation-duration: 10s;
  animation-delay: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`;
const Nose = styled.path``;
const Ear = styled.path``;
const Eye = styled.path``;

const SVG = styled.svg<SVGProps>`
  height: ${props => getSize(props.size)}px;
  width: auto;
  > * {
    fill: currentColor;
  }
`;

export function Logo(props: SVGProps) {
  return (
    <SVG {...props} viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <Head d="M256,0 L477.702503,128 L477.702503,384 L256,512 L34.2974966,384 L34.2974966,128 L256,0 Z M256,23.094 L54.297,139.547 L54.297,372.452 L256,488.905 L457.702,372.453 L457.702,139.546 L256,23.094 Z" fill-rule="nonzero" />
      <Face>
        <Nose d="M303,308 L303,375 L210,375 L210,308 L303,308 Z M283,328 L230,328 L230,355 L283,355 L283,328 Z" fill-rule="nonzero" />
        <Ear d="M151,35 L151,125 L58,125 L58,35 L151,35 Z M131,55 L78,55 L78,105 L131,105 L131,55 Z" fill-rule="nonzero" />
        <Ear d="M455,35 L455,125 L362,125 L362,35 L455,35 Z M435,55 L382,55 L382,105 L435,105 L435,55 Z" fill-rule="nonzero" />
        <Eye d="M152,298 L152,345 L105,345 L105,298 L152,298 Z M132,318 L125,318 L125,325 L132,325 L132,318 Z" fill-rule="nonzero" />
        <Eye d="M409,298 L409,345 L362,345 L362,298 L409,298 Z M389,318 L382,318 L382,325 L389,325 L389,318 Z" fill-rule="nonzero" />
      </Face>
    </SVG>
  )
}

Logo.defaultProps = {
  size: 'md'
}