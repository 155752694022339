import {
  // Typography,
  Box,
  ButtonGroup,
  Button,
} from 'components';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CanvasManager from './canvasManager';
import { useSetRecoilState } from 'recoil';
import { messagesAtom, addNewMessage } from 'atoms';
import { canvasToImg, generateId } from 'helpers';
import { CanvasRecorderContainer } from 'containers';

const DebugWrapper = styled(Box)`
  position: absolute;
  top: 1vw;
  right: 1vw;
  background-color: ${props => props.theme.palette.background.main};
  color: ${props => props.theme.palette.background.contrastText};
  border-radius: ${props => props.theme.borderRadius}px;
  opacity: 0;
  transition: opacity 200ms linear;
  &:hover {
    opacity: 1;
  }
`;


const Wrapper = styled.section`
  width: 100vw;
  height: 100vh;
`;

const Canvas = styled.canvas`
  width: 100vw;
  height: 100vh;
  /* background-color: #673ab7; */
  /* background-color: green; */
`;

export function WorldContainer() {
  const canvas = useRef<HTMLCanvasElement>(null);
  const addMessage = useSetRecoilState(messagesAtom);
  const [showRecordingBar, setShowRecordingBar] = useState(false);

  useEffect(() => {
    const manager = new CanvasManager({
      canvas: canvas.current!,
    });
    // manager.start();

    return () => {
      manager.unmount();
    }
  }, []);


  const createImageClickHandler = () => {
    const image = canvasToImg({
      canvas: canvas.current!,
      extension: 'png'
    })
    // window.location.href = image;
    addMessage((prevMessa) => addNewMessage(prevMessa, {
      id: generateId(),
      title: `message number`,
      message: (
        <Box>
          <Box>
            <img src={image} style={{ width: 380 }} alt="" />
          </Box>
          <Box py={1}>
            <Button variant="contained" color="secondary" onClick={() => {
              window.location.href = image;
            }}>
              Download
            </Button>
          </Box>
        </Box>
      )
    }));
  }

  const createVideoClickHandler = () => {
    setShowRecordingBar(prev => !prev);
  }

  return (
    <Wrapper>
      <Canvas width="500" height="500" ref={canvas} />
      <DebugWrapper p={.5}>
        <ButtonGroup
          variant="contained"
          color="primary"
        >
          <Button
            onClick={createImageClickHandler}
          >
            Create Image
          </Button>
          <Button
            onClick={createVideoClickHandler}
          >
            Create Video
          </Button>
        </ButtonGroup>
      </DebugWrapper>
      {canvas.current && (
        <CanvasRecorderContainer show={showRecordingBar} canvas={canvas.current} />
      )}
    </Wrapper>
  )
}