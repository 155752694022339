import { Box, Divider } from "components";
import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";

const SVG = styled.svg`
  width: 100vw;
  height: 100vh;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='gray' fill-opacity='0.2'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); */
  /* background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='gray' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); */
`;

const ToolsWrapper = styled(Box)`
  width: 52px;
  border-radius: ${props => props.theme.borderRadius}px;
  position: fixed;
  left: ${props => props.theme.spacing.size[2]};
  top: ${props => props.theme.spacing.size[2]};
  display: grid;
  gap: ${props => props.theme.spacing.size[1]};
  grid-template-rows: repeat(auto, 1fr);
  border: 1px solid ${props => props.theme.palette.divider};
`;

interface ColorProps {
  color?: string;
  stroke?: string;
}

const Color = styled.div<ColorProps>`
  aspect-ratio: 5 / 5;
  background-color: ${props => props.color || 'transparent'};
  border: 1px solid ${props => props.stroke || 'transparent'};
  border-radius: 100%;
  width: 100%;
`;

function Tools() {
  return (
    <ToolsWrapper p={.7}>
      <Box>
        <Color color="white" />
      </Box>
      <Box>
        <Color color="red" />
      </Box>
      <Box>
        <Color color="blue" />
      </Box>
      <Box>
        <Color color="green" />
      </Box>
      <Divider />
      <Box>
        <Color stroke="white" />
      </Box>
      <Box>
        <Color stroke="red" />
      </Box>
      <Box>
        <Color stroke="blue" />
      </Box>
      <Box>
        <Color stroke="green" />
      </Box>
    </ToolsWrapper>
  )
}

interface ViewBoxProps {
  x: number;
  y: number;
  w: number;
  h: number;
}

export function SvgDraw() {
  const [zoom] = useState(1);
  const [viewBox, setViewBox] = useState<ViewBoxProps>({
    x: 0,
    y: 0,
    w: 0,
    h: 0,
  });

  useEffect(() => {
    const updateViewBox = () => {
      setViewBox(prev => ({
        ...prev,
        w: window.innerWidth / zoom,
        h: window.innerHeight / zoom
      }))
    }
    window.addEventListener('resize', updateViewBox);
    updateViewBox();
    return () => {
      window.removeEventListener('resize', updateViewBox);
    }
  }, [zoom]);

  return (
    <Fragment>
      <SVG viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`}>
        <text
          fontSize={16}
          y={viewBox.h / 2}
          x={viewBox.w / 2}
          textAnchor="middle"
        >
          Needs to be worked on
        </text>
      </SVG>
      <Tools />
    </Fragment>
  );
}