import { useContext } from "react";
import { DataBox } from "./DataBox";
import { HomeCountext } from './Context';

export function TotalsContainer() {
  const {state} = useContext(HomeCountext);
  const {
    operatingExpenses,
    mortgageTotal,
    totalCost,
    monthlyNetOperatingIncome,
    annualizedNetOperatingIncome,
    capitalizationRate,
    monthlyNet,
    annualizedNet,
    annualizedReturnOnInvestment
  } = state;

  const items = [
    {
      name: 'Operating Expenses',
      value: operatingExpenses,
      uom: '$'
    },
    {
      name: 'Mortgage',
      value: mortgageTotal,
      uom: '$'
    },
    {
      name: 'Expenses + Mortgage',
      value: totalCost,
      uom: '$'
    },
  ]

  const total = [
    {
      title: 'Monthly Net Operating Income',
      value: monthlyNetOperatingIncome,
    },
    {
      title: 'Annualized Net Operating Income',
      value: annualizedNetOperatingIncome,
    },
    {
      title: `Capitalization Rate`,
      value: capitalizationRate,
      uom: '%'
    },
    {
      title: `Monthly Net`,
      value: monthlyNet,
      uom: '$'
    },
    {
      title: `Annualized Net`,
      value: annualizedNet,
      uom: '$'
    },
    {
      title: `Annualized Return On Investment`,
      value: annualizedReturnOnInvestment,
      uom: '%'
    }
  ]

  return (
    <DataBox
      title="Analysis"
      items={items}
      total={total}
      showSeverity
    />
  )
}