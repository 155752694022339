import { Box, Button, ButtonGroup, Typography } from 'components';
import { useContext } from 'react';
import { ACTION, GameContext } from '../gameManager';

export function GameOverScene() {
  const { dispatch, state } = useContext(GameContext);
  const { score } = state;

  return (
    <Box>
      <Typography as="h1">GAME OVER</Typography>
      <Typography as="h3" paddingBottom>{`Score: ${score}`}</Typography>
      <ButtonGroup
        color="primary"
        variant="outlined"
      >
        <Button
          onClick={() => {
            dispatch({
              type: ACTION.RESET
            })
          }}
        >
          Back Home
        </Button>
        <Button
          onClick={() => {
            dispatch({
              type: ACTION.START
            })
          }}
        >
          Play Again
        </Button>
        <Button
          onClick={() => {
            dispatch({
              type: ACTION.LEADERBOARD
            })
          }}
        >
          Leaderboard
        </Button>
      </ButtonGroup>
    </Box>
  )
}