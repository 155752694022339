import styled, { keyframes } from 'styled-components';

const animateRotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const animateStroke = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

const Path = styled.circle`
  animation: ${animateStroke} 3s ease-in-out infinite;
  stroke: ${props => props.theme.palette.primary.main} !important;
  fill: none !important;
  stroke-linecap: round;
`

const SVG = styled.svg`
  animation: ${animateRotate} 2s linear infinite;
`;


export interface SpinnerProps {
}

export function Spinner(props: SpinnerProps) {
  const size = 18;
  const stroke = 2;
  return (
    <SVG {...props}
      width={size}
      height={size}
      focusable="false"
      viewBox={`0 0 ${size} ${size}`}
      aria-hidden="true"
    >
      <circle 
        fill="none"
      />
      <Path
        cx={size/2}
        cy={size/2}
        r={size/2 - stroke}
        strokeWidth={stroke}
      />
    </SVG>
  )
}