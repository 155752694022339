import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

function Portal(props: { children: any }): React.ReactPortal | null {
  const { children } = props;
  const [elNode, setElNode] = React.useState<HTMLElement | null>(null);

  useEffect(() => {
    setElNode(document.body);
  }, []);

  return elNode && createPortal(children, elNode!);
}


export default Portal;
