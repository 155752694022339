import { useLayoutEffect, useRef } from "react";
import styled, { useTheme } from 'styled-components';
import { BoxGeometry, Mesh, MeshBasicMaterial, PerspectiveCamera, Scene, WebGLRenderer } from "three";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  &,canvas {
    background-color: ${props => props.theme.palette.primary.main};
  }
`;

interface Manager {
  camera: PerspectiveCamera,
  renderer: WebGLRenderer
}

export function ThreeScene() {
  const container = useRef<HTMLDivElement>(null);
  const manager = useRef<Manager | null>({
    renderer: new WebGLRenderer(),
    camera: new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000),
  })
  const { palette } = useTheme();
  useLayoutEffect(() => {
    const currentContainer = container.current;
    let scene = new Scene();
    const { renderer, camera } = manager.current!;
    // 
    renderer!.setSize(window.innerWidth, window.innerHeight);
    currentContainer?.appendChild(renderer!.domElement);

    // geo
    const geometry = new BoxGeometry(1, 1, 1);
    const material = new MeshBasicMaterial({ color: Number(`0x${palette.primary.main.replace('#', '')}`) });
    const cube = new Mesh(geometry, material);
    scene.add(cube);
    camera.position.z = 5;

    const animate = function () {
      frameId = requestAnimationFrame(animate);
      cube.rotation.x += 0.01;
      cube.rotation.y += 0.01;
      renderer!.render(scene, camera);
      console.log('a')
    };

    let frameId = requestAnimationFrame(animate);

    return () => {
      // DESTROY REFERENCES
      cancelAnimationFrame(frameId);
      scene.clear();
      currentContainer?.removeChild(renderer!.domElement);
      manager.current = null;
    }
  }, [palette.primary.main]);


  return <Wrapper ref={container} />
}