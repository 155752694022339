import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

  html {
    font-size: 16px;
  }

  html, 
  body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    font-smooth: subpixel-antialiased;
  }

  body {
    color: ${(props): string => props.theme.palette.background.contrastText};
    font-family: ${({ theme }) => theme.typography.body.font};
    font-weight: ${({ theme }) => theme.typography.body.fontWeight};
    font-size: ${({ theme }) => theme.typography.body.fontSize};
    letter-spacing: ${({ theme }) => theme.typography.body.letterSpacing};
    line-height:  ${({ theme }) => theme.typography.body.lineHeight};
    background: ${(props): string => props.theme.palette.background.main};
  }

  * {
    box-sizing: border-box;
  }

  *:before, *:after {
    box-sizing: inherit;
  }

  #app {
    width: 100vw;
    min-height: 100vh ;
  }

  a {
    color: inherit;
  }

  *[role=button] {
    cursor: pointer;
  }
`;