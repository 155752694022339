// TODO: work in progress
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Popover,  PopoverProps } from '../Popover';
import { List, ListItem, ListItemProps } from '../List';
import { Paper, PaperProps } from '../Paper';
import { getElementBoxInfo } from '../utils/element';

export interface MenuItemProps extends ListItemProps {
  selected?: boolean;
}

export const MenuItem = styled(ListItem)<MenuItemProps>``;

MenuItem.displayName = 'MenuItem';
MenuItem.defaultProps = {
  role: 'button'
}

interface MenuProps extends PaperProps {
  showAnchor?: PopoverProps['showAnchor']
  children: any;
  onClose?(): void;
  target?: HTMLElement | null;
  anchorOrigin?: PopoverProps['anchorOrigin'];
  transformOrigin?: PopoverProps['transformOrigin'];
}

export function Menu(props: MenuProps) {
  const {
    children,
    target,
    onClose,
    anchorOrigin,
    transformOrigin,
    showAnchor,
    ...paperProps
  } = props;
  const [targetEl, setTargetEl] = useState(target);
  const items = React.Children.map(children, (child) => child);

  useEffect(() => {
    setTargetEl(target);
  }, [target]);

  if (!targetEl) return null;
  const hasBorder = paperProps.variant === 'outlined';
  
  return (
    <Popover
      active
      onClose={onClose}
      target={targetEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      showAnchor={showAnchor}
    >
      <Paper {...paperProps} style={{marginTop: hasBorder ? -1: undefined}}>
        <List
          styles={{
            minWidth: getElementBoxInfo(targetEl).width - (hasBorder ? 2 : 0) ?? 0,
            maxHeight: '100vh',
            overflow: 'auto'
          }}
        >
          {items}
        </List>
      </Paper>
    </Popover>
  );
};

Menu.defaultProps = {
  variant: "outlined",
  anchorOrigin: {
    horizontal: 'center',
    vertical: 'top',
  },
  transformOrigin: {
    horizontal: 'center',
    vertical: 'top',
  },
}


