import styled from 'styled-components';

export interface InputLabelProps {
  paddingBottom?: boolean;
}

export const InputLabel = styled.label<InputLabelProps>`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  width: auto;
  padding-bottom: ${(props) => props.paddingBottom ? props.theme.spacing.space('xs') : 0};
  font-size: ${(props) => props.theme.typography.small.fontSize};
  text-transform: capitalize;
`;

InputLabel.defaultProps = {
  paddingBottom: true
}