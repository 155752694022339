import React, { useContext, useRef } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { SVG, Line } from './Svg';

const Marker = styled.circle`
  & + text {
  display: none;
  }
  &:hover + text {
    display: block;
  }
`;

interface Point {
  valueX: number;
  valueY: number;
  label: string;
  color?: string;
}

interface ScatterChartProps {
  title?: string;
  width: number;
  height: number;
  stepsY?: number;
  stepsX?: number;
  points?: Point[];
  markerSize?: number;
}

export function ScatterChart(props: ScatterChartProps) {
  const { palette } = useContext(ThemeContext);
  const {
    title,
    width = 0,
    height = 0,
    points = [],
    markerSize = 5,
    stepsX,
    stepsY,
  } = props;
  const padding = {
    left: 50,
    right: 40,
    top: 150,
    bottom: 50,
  }
  const chartBox = {
    x: padding.left,
    y: padding.top,
    width: width - padding.left - padding.right,
    height: height - padding.top - padding.bottom,
  }
  const pointsXValues = points.map(bar => bar.valueX);
  const pointsYValues = points.map(bar => bar.valueY);
  const highestXValue = Math.max(...pointsXValues);
  const highestYValue = Math.max(...pointsYValues);

  // 
  return (
    <SVG width={width} height={height}>
      {title && <text x={20} y={40} fontSize={20}>{title}</text>}
      {points.map((point, index) => {
        const normalizedX = point.valueX / highestXValue;
        const normalizedY = point.valueY / highestYValue;
        const x = normalizedX * chartBox.width + padding.left;
        const y = chartBox.height - normalizedY * chartBox.height + padding.top;
        return (
          <g key={index}>
            <Marker
              cx={x}
              cy={y}
              r={markerSize}
              fill={palette.primary.main}
            />
            <text
              x={x}
              y={y - markerSize * 2 - 10}
              fontSize={10}
              textAnchor="start"
            >
              <tspan>{`x: ${point.valueX.toFixed(2)}`}</tspan>
              <tspan dy="1.2em" x={x}>{`y: ${point.valueY.toFixed(2)}`}</tspan>
            </text>
          </g>
        )
      })}
      {stepsY && points.length &&
        <g>
          {new Array(stepsY).fill(null).map((_, step, array) => {
            const spacing = chartBox.height / array.length;
            const y = spacing * step + chartBox.y;
            const lineNum = highestYValue * (1 - step / stepsY)
            const label = Math.round(lineNum * 100) / 100
            return (
              <React.Fragment key={step}>
                <Line
                  x1={0}
                  y1={y}
                  x2={width}
                  y2={y}
                  strokeDasharray="4 10"
                  stroke={palette.secondary.main}
                  strokeOpacity="0.5"
                />
                <text
                  x={padding.left - 10}
                  y={y - 10}
                  fontSize={10}
                  textAnchor="end"
                >
                  {label.toFixed(2)}
                </text>
              </React.Fragment>
            )
          })}
          <text
            x={padding.left - 10}
            y={chartBox.height + padding.top - 10}
            fontSize={10}
            textAnchor="end"
          >
            0
          </text>
          <Line
            x1={0}
            y1={chartBox.height + padding.top}
            x2={width}
            y2={chartBox.height + padding.top}
            strokeDasharray="4 10"
            stroke={palette.secondary.main}
            strokeOpacity="0.5"
          />
        </g>
      }
      {stepsX && points.length &&
        <g>
          {new Array(stepsX).fill(null).map((_, step, array) => {
            const spacing = chartBox.width / array.length;
            const x = spacing * step + padding.left;
            const label = highestXValue * (step / stepsX);
            return (
              <React.Fragment key={step}>
                <text
                  x={x}
                  y={chartBox.height + padding.top + padding.bottom / 2}
                  fontSize={10}
                  textAnchor="middle"
                >
                  {(label).toFixed(2)}
                </text>
                <Line
                  x1={x}
                  y1={padding.top}
                  x2={x}
                  y2={height - padding.bottom}
                />
              </React.Fragment>
            )
          })}
          <text
            x={chartBox.width + padding.left}
            y={chartBox.height + padding.top + padding.bottom / 2}
            fontSize={10}
            textAnchor="middle"
          >
            {highestXValue.toFixed(2)}
          </text>
          <line
            x1={chartBox.width + padding.left}
            y1={padding.top}
            x2={chartBox.width + padding.left}
            y2={height - padding.bottom}
            strokeDasharray="4 10"
            stroke={palette.secondary.main}
            strokeOpacity="0.5"
          />
        </g>
      }
    </SVG>
  )
}