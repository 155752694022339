import styled, { DefaultTheme, Space, BreakPoints } from 'styled-components';

export interface GridProps {
  gap?: Space | [Space, Space];
  breakPoints?: {
    gap?: Space | [Space, Space];
    minWidth: keyof BreakPoints,
    columns: number
  }[]
}

const getGap = (propsGap: GridProps['gap'], theme: DefaultTheme) => {
  const gap = (Array.isArray(propsGap) ? propsGap : [propsGap ?? 0]).reduce((acc, gap) => {
    const space = theme.spacing.space(gap);
    return acc ? `${acc} ${space}` : space;
  }, '');

  return gap;
}

export const Grid = styled.div<GridProps>`
display: grid;
gap: ${(props) => getGap(props.gap, props.theme)};
${(props) => props.breakPoints?.map((breakpoint) => {
  const minWidth = props.theme.breakPoints[breakpoint.minWidth];
  const gap = getGap(breakpoint.gap ?? props.gap, props.theme);
  return (
    `
    @media only screen and (min-width: ${minWidth}) {
      grid-template-columns: repeat(${breakpoint.columns}, 1fr);
      gap: ${gap};
    }
    `
  )
})}
`;

export interface GridItemProps {
  gridColumn?: number | string;
  breakPoints?: {
    minWidth: keyof BreakPoints,
    gridColumn?: number | string;
  }[]
}

export const GridItem = styled.div<GridItemProps>`
  ${(props) => props.breakPoints?.map((breakpoint) => {
    const minWidth = props.theme.breakPoints[breakpoint.minWidth];
    const gridColumn = breakpoint.gridColumn;
    return (
      `
        @media only screen and (min-width: ${minWidth}) {
          grid-column: ${gridColumn ?? ''};
        }
      `
    )
  })}
`;