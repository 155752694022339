import { createContext } from "react";
import { initialState, ACTIONS } from './reducer';

interface HomeContextProps {
  dispatch: React.Dispatch<ACTIONS>;
  state: typeof initialState,
}

export const HomeCountext = createContext<HomeContextProps>({
  dispatch: () => {},
  state: initialState
});