import { InputBase, InputProps, InputBaseBefore, InputBaseAfter, InputWrapper } from "../Input";
import styled from 'styled-components';
import { Dropdown } from '../Dropdown';
import { Box } from '../Box';
import { Children, isValidElement, cloneElement, useState, forwardRef } from "react";
import { ReactComponent as KeyboardArrowDownIcon } from '../../icons/KeyboardArrowDownIcon.svg';
import { Spinner } from '../Spinner';

const arrowOuterWrapperSize = '1.5rem';
const SelectBody = styled(InputWrapper)`
  white-space: nowrap;
  text-overflow: ellipsis;
  > * {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0;
    padding-right: 0;
  }
`;

const SelectWrapper = styled(InputBase)`
  cursor: ${props => props.disabled ? 'initial' : 'pointer'};
  position: relative;
  &:focus {
    outline: 1px solid ${props => props.theme.palette.outline};
  }
`;

const Arrow = styled(Box)<{open?: boolean;}>`
  width: ${arrowOuterWrapperSize};
  position: relative;
  svg {
    width: auto;
    height: calc(50%);
    transition: transform .1s ease-in-out;
    transform: rotate(${props => props.open ? 180 : 0}deg);
    * {
      fill: currentColor;
    }
  }
`;

export interface SelectProps extends InputProps {

}

export const Select = forwardRef<HTMLInputElement, SelectProps>(
  (props, ref) => {
  const {
    fullWidth,
    before,
    after,
    children,
    focus,
    error,
    loading,
    ...inputProps
  } = props;
  const [target, setTarget] = useState<HTMLDivElement | null>(null);
  const [value, setValue] = useState(inputProps.value ?? '');

  const onOptionClickHandler = (child: React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactPortal) => {
    return (event:  React.MouseEventHandler<HTMLElement>) => {
      let newValue;
      newValue = child.props.value;
      if (child.props.onClick) {
        child.props.onClick(event);
      }

      if (value !== newValue) {
        setValue(newValue);
        if (inputProps.onChange) {
          const clonedEvent = event;
          Object.defineProperty(clonedEvent, 'target', {
            writable: true,
            value: { value: newValue },
          });
          inputProps.onChange(clonedEvent, child);
        }
      }

      setTarget(null);
    }
  }


  const childrenArray = Children.toArray(children);
  const items = childrenArray.map((child, index) => {
    if (!isValidElement(child)) {
      return null;
    }

    const selected = childrenArray.findIndex((child => {
      return isValidElement(child) && child.props.value === value;
    })) === index;

    return cloneElement(child, {
      selected,
      onClick: onOptionClickHandler(child),
      role: 'option',
      value: undefined, // The value is most likely not a valid HTML attribute.
      'data-value': child.props.value, // Instead, we provide it as a data attribute.
    })
  });

  const selectedChild = childrenArray.find((child) => {
    return isValidElement(child) && child.props.value === value;
  });

  return (
    <SelectWrapper
      disabled={inputProps.disabled}
      fullWidth={!Boolean(fullWidth)}
      onMouseDown={(ev) => {
        if(inputProps.disabled) return;
        setTarget(ev.currentTarget);
      }}
      onFocus={(ev) => {
        if(inputProps.disabled) return;
        setTarget(ev.currentTarget);
      }}
      onBlur={()=> {
        setTarget(null);
      }}
      tabIndex={0}
    >
      {before && <InputBaseBefore>{before}</InputBaseBefore>}
      <SelectBody>
        {selectedChild && cloneElement(selectedChild as any, {
          role: 'none',
        })}
      </SelectBody>
      <InputBaseAfter>
        <Box display="flex" alignItems="center">
          {loading && (
            <Box pr={.5} display="flex" alignItems="center">
              <Spinner />
            </Box>
          )}
          {after && (
            <Box pr={.5} display="flex" alignItems="center">
              {after}
            </Box>
          )}
          <Box display="flex" alignItems="center">
            <Arrow
              display="inline-flex"
              alignItems="center"
              justify="center"
              open={Boolean(target)}
            >
              <KeyboardArrowDownIcon />
            </Arrow>
          </Box>
        </Box>
      </InputBaseAfter>
      <input ref={ref} {...inputProps} type="hidden" />
      <Dropdown 
        target={target}
        onClose={() => {
          setTarget(null);
        }}
      >
        {items}
      </Dropdown>
    </SelectWrapper>
  );
  }
);