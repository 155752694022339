// TODO: finish this component
import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react';
import styled from 'styled-components';
import { getElementAnchors, getElementSizeAnchors } from '../utils/element';
import { clamp } from '../utils/number';
import { setRef } from '../utils/ref';
import { Paper } from '../Paper';
import { Modal } from '../Modal';

const PopoverWrapper = styled(Paper)`
  position: fixed;
  visibility: hidden;
  z-index: ${(props): number => props.theme.zIndex.popover};
  top: 0;
  left: 0;
`;

const PopoverAnchor = styled.div`
  position: fixed;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background: red;
  z-index: ${(props): number => props.theme.zIndex.popover + 1};
  transform: translate(-50%, -50%);
`;

PopoverAnchor.displayName = 'PopperAnchor';

type HorizontalPos = 'left' | 'center' | 'right';
type VerticalPos = 'top' | 'center' | 'bottom';

export interface PopoverProps {
  children: any;
  active: boolean;
  onClose?(): void;
  target: HTMLElement;
  showAnchor?: boolean;
  anchorOrigin?: {
    horizontal?: HorizontalPos;
    vertical: VerticalPos;
  };
  transformOrigin?: {
    horizontal: HorizontalPos;
    vertical: VerticalPos;
  };
}

export const Popover = (props: PopoverProps): JSX.Element => {
  const {
    children,
    active,
    onClose,
    target,
    showAnchor = false,
    anchorOrigin = {
      horizontal: 'center',
      vertical: 'top',
    },
    transformOrigin = {
      horizontal: 'center',
      vertical: 'top',
    },
  } = props;
  const [targetEl, setTargetEl] = useState<HTMLElement | null>(null);
  const [styles, setStyles] = useState({});
  const [dotAnchor, setDotAnchor] = useState({});
  const container = useRef();

  useEffect(() => {
    setTargetEl(target ?? null);
  }, [target]);

  const handleOpen = useCallback((): void => {
    if (!targetEl || !container.current) return;

    // Window
    const { pageYOffset, innerWidth, innerHeight } = window;
    const windowBottom = pageYOffset + innerHeight;
    const anchorsPosition = getElementAnchors(targetEl);
    const containerAnchors = getElementSizeAnchors(container.current);
    const targetLeft = anchorsPosition.horizontal[anchorOrigin.horizontal ?? 'center'];
    const targetTop = anchorsPosition.vertical[anchorOrigin.vertical];
    const containerLeft = containerAnchors.horizontal[transformOrigin.horizontal];
    const containerTop = containerAnchors.vertical[transformOrigin.vertical];

    setDotAnchor({
      left: targetLeft,
      top: targetTop,
    });

    let left = targetLeft - containerLeft;
    let top = targetTop - containerTop;

    if (left + containerAnchors.horizontal.right > innerWidth) {
      left -= (left + containerAnchors.horizontal.right) - innerWidth;
    }

    if (top + containerAnchors.vertical.bottom > windowBottom) {
      top -= (top + containerAnchors.vertical.bottom) - windowBottom;
    }

    setStyles({
      left: clamp(left, 0, innerWidth),
      top: clamp(top, pageYOffset, windowBottom),
      visibility: 'initial',
    });
  }, [anchorOrigin.horizontal, anchorOrigin.vertical, targetEl, transformOrigin.horizontal, transformOrigin.vertical]);

  useEffect(() => {
    const onResizeEventHandler = (): void => {
      if (!container.current) return;
      // TODO: add debounce here
      handleOpen();
    };
    window.addEventListener('resize', onResizeEventHandler);
    document.addEventListener('scroll', onResizeEventHandler);

    return (): void => {
      window.removeEventListener('resize', onResizeEventHandler);
      document.removeEventListener('scroll', onResizeEventHandler);
    };
  }, [handleOpen]);

  const handleRef = React.useCallback(
    (node) => {
      setRef(container, node);
      if (node) {
        handleOpen();
      }
    },
    [container, handleOpen],
  );

  return (
    <Modal
      active={active}
      onClose={onClose}
      backdropProps={{
        invisible: true,
      }}
    >
      <PopoverWrapper ref={handleRef} style={styles}>
        {children}
      </PopoverWrapper>
      {showAnchor && <PopoverAnchor style={dotAnchor} />}
    </Modal>
  );
};

Popover.displayName = 'Popover';

