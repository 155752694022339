export type ElementSize = {
  width: number;
  height: number;
  center: number;
};

export const getElementSize = (el: HTMLElement): ElementSize => {
  const width = el.offsetWidth;
  const height = el.offsetHeight;
  return {
    center: (width / 2) + (height / 2),
    width,
    height,
  };
};

export type ElementPosition = {
  top: number;
  left: number;
  vCenter: number;
  hCenter: number;
  right: number;
  bottom: number;
};

export const getElementPosition = (el: HTMLElement): ElementPosition => {
  const { top, left } = el.getBoundingClientRect();
  const { width, height } = getElementSize(el);
  return {
    top,
    left,
    vCenter: (height / 2),
    hCenter: (width / 2),
    right: (left + width),
    bottom: (top + height),
  };
};

export const getElementAnchors = (el: HTMLElement): any => {
  const { top, left } = el.getBoundingClientRect();
  const { width, height } = getElementSize(el);
  return {
    horizontal: {
      left,
      center: left + width / 2,
      right: left + width,
    },
    vertical: {
      top,
      center: top + height / 2,
      bottom: top + height,
    },
  };
};

export const getElementSizeAnchors = (el: HTMLElement): any => {
  const { width, height } = getElementSize(el);
  return {
    horizontal: {
      left: 0,
      center: width / 2,
      right: width,
    },
    vertical: {
      top: 0,
      center: height / 2,
      bottom: height,
    },
  };
};

export type ElementBoxInfo = ElementSize & ElementPosition;

export const getElementBoxInfo = (el: HTMLElement): ElementBoxInfo => ({
  ...getElementSize(el),
  ...getElementPosition(el),
});

export const centerContainerBaseOnTarget = (
  target: HTMLElement,
  container: HTMLElement,
): { left: string } => {
  const { clientWidth: windowWidth } = document.body;
  const targetInfo = getElementBoxInfo(target);
  const containerInfo = getElementBoxInfo(container);
  const translateX = (containerInfo.hCenter - targetInfo.hCenter);
  const containerNewLeftPosition = containerInfo.left - translateX;
  const containerNewRightPosition = containerNewLeftPosition + containerInfo.width;
  let offset = 0;

  /* istanbul ignore else  */
  if (containerNewRightPosition > windowWidth) {
    offset = (containerNewRightPosition - windowWidth) * -1;
  } else if (containerNewLeftPosition < 0) {
    offset = containerNewLeftPosition * -1;
  }

  return {
    left: `${(translateX - offset) * -1}px`,
  };
};
