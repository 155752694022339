import styled from 'styled-components';
import { Input, InputProps } from '../Input';
import { InputLabel } from '../InputLabel';
import { Box } from '../Box';
import { Typography } from '../Typography';
import { Select } from '../Select';
import { forwardRef } from 'react';

const RequiredMessage = styled(Typography)`
  color: ${props => props.theme.palette.error.main};
`;

const FormControl = styled.div<{ fullWidth: boolean }>`
  ${(props): string => (props.fullWidth ? 'width: 100%;' : '')}
  & + & {
    padding-top: ${props => props.theme.spacing.space(1)};
  }
`;

const OptionalLabel = styled(Box)`
  opacity: 0.75;
`;

const InputLabelExtension = styled(InputLabel)<{align: 'left' | 'right'}>`
  margin-left: ${props => props.align === 'right' ? 'auto' : ''};
  margin-right: ${props => props.align === 'left' ? 'auto' : ''};
  width: auto;
  white-space: nowrap;
  cursor: initial;
  padding-right: ${(props) => props.align === 'left' ? props.theme.spacing.space('xs') : 0};
  padding-left: ${(props) => props.align === 'right' ? props.theme.spacing.space('xs') : 0};
`;

InputLabelExtension.defaultProps = {}

export interface TextFieldProps extends InputProps {
  select?: boolean;
  label?: string;
  isValid?: boolean | null;
  required?: boolean;
  validationMessage?: string;
  beforeLabel?: any;
  afterLabel?: any;
  children?: any;
  wrapperRef?: (node: HTMLDivElement) => void;
}
export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (props, ref) => {
    const {
      select,
      label,
      fullWidth = false,
      before,
      after,
      isValid,
      validationMessage = 'Required Field',
      afterLabel,
      beforeLabel,
      children,
      wrapperRef,
      ...inputProps
    } = props;
    const hasErrors = isValid === false && inputProps?.required;

    return (
      <FormControl fullWidth={fullWidth}>
        <Box display="flex">
          {beforeLabel && <InputLabelExtension align="left" as="div">{beforeLabel}</InputLabelExtension>}
          {label && (
            <InputLabel
              htmlFor={inputProps.id}
            >
              {label} {!inputProps.required && <OptionalLabel as="small" pl={.5}>(Optional)</OptionalLabel>}
            </InputLabel>
          )}
          {afterLabel && <InputLabelExtension align="right" as="div">{afterLabel}</InputLabelExtension>}
        </Box>
        <Box ref={wrapperRef}>
          {!select && (
            <Input
              fullWidth={fullWidth}
              before={before}
              after={after}
              error={hasErrors}
              ref={ref}
              {...inputProps}
            />
          )}
          {select && (
            <Select 
              fullWidth={fullWidth}
              before={before}
              after={after}
              error={hasErrors}
              ref={ref}
              {...inputProps}
            >
              {children}
            </Select>
          )}
        </Box>
        {hasErrors && (
          <Box pt={.5}>
            <RequiredMessage as="small">{validationMessage}</RequiredMessage>
          </Box>
        )}
        {!select && children}
      </FormControl>
    );
  }
);

TextField.displayName = 'TextField';


