import { DefaultTheme, Typography } from 'styled-components'

const typography: Typography = {
  font: `-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif`,
  fontWeight: 400,
  fontSize: '1em',
  letterSpacing: '0.01em',
  lineHeight: 1.8,
}

const space = (n: number | 'xs' | 'sm' | 'md' | 'lg') => {
  switch (n) {
    case 'xs':
      return `${.5}rem`;
    case 'sm':
      return `${1}rem`;
    case 'md':
      return `${2}rem`;
    case 'lg':
      return `${3}rem`;
    default:
      return `${n}rem`;
  }
}

export const theme: DefaultTheme = {
  topBar: 80,
  pageGutter: '4vw',
  zIndex: {
    modal: 9999,
    popover: 9990,
    tooltip: 9995,
  },
  borderRadius: 3,
  breakPoints: {
    xxs: '320px',
    xs: '480px',
    sm: '640px',
    md: '960px',
    lg: '1280px',
    xl: '1600px',
    xxl: '1920px',
  },
  spacing: {
    size: [0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val) => {
      return space(val)
    }),
    space,
  },
  typography: {
    display: {
      ...typography,
      fontSize: '5vw',
      lineHeight: 1,
      fontWeight: 700,
      letterSpacing: '.1em'
    },
    body: {
      ...typography,
      fontSize: '1rem'
    },
    blockquote: {
      ...typography,
      fontSize: '2em',
      fontWeight: 600,
      lineHeight: 1.1,
    },
    headline: {
      ...typography,
      fontSize: '3rem',
      lineHeight: 1.1,
    },
    subHeading: {
      ...typography,
      fontSize: '2em',
      lineHeight: 1.1,
      fontWeight: 600,
    },
    subTitle1: {
      ...typography,
      fontSize: '1em',
      fontWeight: 600,
      lineHeight: 1.1,
    },
    subTitle2: {
      ...typography,
      fontSize: '0.75em',
      fontWeight: 600,
      lineHeight: 1.1,
    },
    h3: {
      ...typography,
      fontSize: '1.2em',
      fontWeight: 600,
      lineHeight: 1.1,
    },
    h4: {
      ...typography,
      lineHeight: 1.1,
    },
    h5: {
      ...typography,
      lineHeight: 1.1,
    },
    h6: {
      ...typography,
      lineHeight: 1.1,
    },
    small: {
      ...typography,
      fontSize: '0.75rem',
      lineHeight: 1.6,
    },
  },
  palette: {
    divider: 'rgba(255,255,255,0.15)',
    hover: 'rgba(255,255,255,0.05)',
    // number from 0 to 100 it will be the amount of contrast with the background
    contrastTextHover: 3,
    outline: '#2196f3',
    common: {
      black: '#ff0000',
      white: '#000000',
    },
    background: {
      dark: '#0b0b14',
      main: '#0b0b14',
      light: '#18182b',
      contrastText: '#ffffff',
      gradient: ['#1b1b31', '#0b0b14']
    },
    primary: {
      dark: '#ff0000',
      main: '#ff0000',
      light: '#ff0000',
      contrastText: '#000000'
    },
    secondary: {
      dark: '#ff0081',
      main: '#ff0081',
      light: '#ff0081',
      contrastText: '#fff'
    },
    error: {
      dark: '#ff0000',
      main: '#ff0000',
      light: '#ff0000',
      contrastText: '#ffffff'
    },
    warning: {
      dark: '#ff9800',
      main: '#ff9800',
      light: '#ff9800',
      contrastText: '#000000'
    },
    info: {
      dark: '#2196f3',
      main: '#2196f3',
      light: '#2196f3',
      contrastText: '#000000'
    },
    success: {
      dark: '#3fff00',
      main: '#3fff00',
      light: '#3fff00',
      contrastText: '#ffffff'
    }
  }
}