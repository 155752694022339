import React, { useContext, useRef } from 'react';
import { ThemeContext } from 'styled-components';
import { Line, SVG } from './Svg';

interface Bar {
  value: number;
  label: string;
  color?: string;
}

interface BarChartProps {
  title?: string;
  width: number;
  height: number;
  showLabels?: boolean;
  showAverage?: boolean;
  steps?: number;
  bars?: Bar[];
}

export function BarChart(props: BarChartProps) {
  const id = useRef(`${(new Date()).getTime()}-BarChart`)
  const { palette } = useContext(ThemeContext);
  const {
    title,
    width = 0,
    height = 0,
    bars = [],
    showLabels,
    showAverage,
    steps
  } = props;
  const barWidth = 10;
  const padding = {
    left: 50,
    right: 40,
    top: 150,
    bottom: 50,
  }
  const chartBox = {
    x: padding.left,
    y: padding.top,
    width: width - padding.left - padding.right,
    height: height - padding.top - padding.bottom,
  }
  const xSpacing = chartBox.width / bars.length;
  const barsValues = bars.map(bar => bar.value);
  const highestValue = Math.max(...barsValues);
  const normalizeHeigthValue = chartBox.height / highestValue;


  const getAverage = () => {
    const total = barsValues.reduce((acc, val) => acc + val, 0);
    const average = total / barsValues.length;

    return average || 0;
  }

  const getAverageY = () => {
    const average = getAverage();
    const normalizeAverage = average / highestValue;
    return (height - normalizeAverage * chartBox.height) - padding.bottom;
  };

  return (
    <SVG width={width} height={height}>
      <defs>
        <mask id={`${id.current}-above`}>
          <rect
            x={0}
            y={getAverageY()}
            width={width}
            height={height - getAverageY()}
            fill="white"
          />
        </mask>
        <mask id={`${id.current}-under`}>
          <rect
            x={0}
            y={0}
            width={width}
            height={getAverageY()}
            fill="white"
          />
        </mask>
      </defs>
      {title && <text x={20} y={40} fontSize={20}>{title}</text>}
      {bars.map((bar, index) => {
        const x = (xSpacing * index) + (xSpacing / 2) - (barWidth / 2) + chartBox.x;
        const h = bar.value * normalizeHeigthValue;
        const y = (chartBox.height - h) + chartBox.y;
        const labelFontSize = 10;
        const labelX = x + (barWidth / 2);
        const labelY = h + y + labelFontSize * 2.5;
        const color = bar.color ?? palette.primary.main;
        return (
          <React.Fragment key={index}>
            {showAverage && (
              <rect
                x={x}
                y={y}
                width={barWidth}
                height={h}
                fill={color}
                fillOpacity={0.2}
                stroke={color}
                mask={showAverage ? `url(#${`${id.current}-under`})` : undefined}
              />
            )}
            <rect
              x={x}
              y={y}
              width={barWidth}
              height={h}
              fill={color}
              stroke={color}
              mask={showAverage ? `url(#${`${id.current}-above`})` : undefined}
            />
            {showLabels && (
              <text
                x={labelX}
                y={labelY}
                fontSize={labelFontSize}
                textAnchor="middle"
              >
                {bar.label}
              </text>
            )}
          </React.Fragment>
        )
      })}
      {showAverage && (
        <g>
          <text
            x={width - 10}
            y={getAverageY() - 10}
            fontSize={10}
            textAnchor="end"
          >
            {getAverage().toFixed(2)}
          </text>
          <Line
            x1={0}
            y1={getAverageY()}
            x2={width}
            y2={getAverageY()}
          />
        </g>
      )}
      {steps && bars.length &&
        <g>
          {new Array(steps).fill(null).map((_, step, array) => {
            const spacing = chartBox.height / array.length;
            const y = spacing * step + chartBox.y;
            const value = highestValue - highestValue * (step / steps)
            return (
              <React.Fragment key={step}>
                <text
                  x={padding.left - 10}
                  y={y - 10}
                  fontSize={10}
                  textAnchor="end"
                >
                  {value.toFixed(2)}
                </text>
                <Line
                  x1={0}
                  y1={y}
                  x2={width}
                  y2={y}
                />
              </React.Fragment>
            )
          })}
        </g>
      }
    </SVG>
  )
}