import { convertHexToRgba } from 'helpers';
import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from '../Box';
import { Spinner } from '../Spinner';

const extrasDefProps: BoxProps = {
  display: 'flex',
  alignItems: 'center',
  justify: 'center'
}

export const InputBaseBefore = styled(Box)`
  min-width: 24px;
  flex: 0 0 auto;
  line-height: 0;
  svg {
    * {
      fill: currentColor;
    }
  }
`;

InputBaseBefore.defaultProps = {
  ...extrasDefProps,
  pl: .5
}

export const InputBaseAfter = styled(Box)`
  min-width: 24px;
  margin-left: auto;
  flex: 0 0 auto;
  line-height: 0;
  svg {
    * {
      fill: currentColor;
    }
  }
`;

InputBaseAfter.defaultProps = {
  ...extrasDefProps,
  pr: .5
}

interface InputBaseProps {
  disabled?: boolean;
  fullWidth?: boolean
  error?: boolean;
  isFocus?: boolean;
}

export const InputBase = styled.div<InputBaseProps>`
  display: flex;
  align-items: center;
  opacity: ${props => props.disabled ? .5 : 1};
  ${(props) => (props.fullWidth ? 'width: 100%;' : '')}
  color: ${(props) => props.theme.palette.background.contrastText};
  border: 1px solid ${(props) => {
    if (props.error) {
      return props.theme.palette.error.main;
    }
    if (props.isFocus) {
      return props.theme.palette.outline;
    }
    return props.theme.palette.divider;
  }};
  border-radius: ${(props): number => props.theme.borderRadius}px;
  padding: 0;
  min-height: 3rem;
  background-color: ${props => props.theme.palette.background.main};
  transition: border 200ms ease-in-out;
`;

export const InputWrapper = styled.div`
  height: inherit;
  padding: 0 1rem;
  flex: 1 1 auto;
  overflow: hidden;
`;

const InputElement = styled.input`
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 100%;
  margin: 0;
  display: block;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  outline: none;
  display: flex;
  align-items: center;
  color: ${props => props.theme.palette.background.contrastText};

  &:disabled {
    color: rgba(0,0,0, 0.5);
  }

  &::placeholder {
    color: ${props => convertHexToRgba(props.theme.palette.background.contrastText, 15)};
    font-style: italic;
  }

  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button
   {
    appearance: textfield;
    background-color: red;
    appearance: none;
  }
`;

export declare type ChangeHandler = (event: {
  target: any;
  type?: any;
}) => void;

export interface InputProps {
  fullWidth?: boolean;
  before?: any;
  after?: any;
  children?: any;
  focus?: HTMLDivElement;
  error?: boolean;
  loading?: boolean;
  // 
  value?: string | number;
  id?: string;
  name: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (event?: any, child?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactPortal) => void;
  onFocus?: (event?: any, child?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactPortal) => void;
  onBlur?: ChangeHandler;
  ref?: (instance: any) => void;
  min?: string | number;
  max?: string | number;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
  required?: boolean;
  disabled?: boolean;
  type?: "text" | "number" | "password"
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const {
      fullWidth,
      before,
      after,
      children,
      focus,
      error,
      loading,
      ...inputProps
    } = props;
    const [isFocus, setIsFocus] = useState(false);

    return (
      <InputBase
        fullWidth={!!(fullWidth)}
        disabled={inputProps.disabled}
        isFocus={isFocus}
        error={error}
      >
        {before && (
          <InputBaseBefore>
            {before}
          </InputBaseBefore>
        )}
        <InputWrapper>
          <InputElement
            ref={ref}
            {...inputProps}
            onFocus={(ev) => {
              setIsFocus(true);
              inputProps?.onFocus && inputProps?.onFocus(ev);
            }}
            onBlur={(ev) => {
              setIsFocus(false);
              inputProps?.onBlur && inputProps?.onBlur(ev);
            }}
          />
        </InputWrapper>
        {(after || loading) && (
          <InputBaseAfter>
            <Box display="flex" alignItems="center">
              {loading && (
                <Box pr={.5} display="flex" alignItems="center">
                  <Spinner />
                </Box>
              )}
              {after && (
                <Box pr={.5} display="flex" alignItems="center">
                  {after}
                </Box>
              )}
            </Box>
          </InputBaseAfter>
        )}
      </InputBase>
    );
  }
);