import {
  Modal,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Grid,
  GridItem,
  TextField,
  DialogActions,
  Button,
  Box
} from "components";
import { isBiggerThanCero, isNotEmpty, numberToDollars } from "helpers";
import { useCallback, useContext, useEffect, useState } from "react"
import { useForm, Controller } from "react-hook-form";
import { HomeCountext } from "./Context"
import { DataBox } from "./DataBox";
import { ACTION } from "./reducer";

interface DialogProps {
  open: boolean;
  onClose: () => void;
}

function DialogContainer(props: DialogProps) {
  const {
    open,
    onClose,
  } = props;
  const {state, dispatch} = useContext(HomeCountext);
  const {
    rent,
    marketRent
  } = state;

  const fields = useCallback((): {
    label: string,
    name: string,
    before: string,
    validate?: (v: string | number) => boolean,
    value: number | string,
    type?: "number" | "text" | "password" | undefined
  }[] => [
    {
      label: 'Rent',
      name: 'rent',
      before: '$',
      validate: isBiggerThanCero,
      value: rent
    },
    {
      label: 'Market Rent',
      name: 'marketRent',
      before: '$',
      validate: isBiggerThanCero,
      value: marketRent
    }
  ], [marketRent, rent])

  const getDefaultValues = useCallback(() => {
    let fieldsObj: {[key: string]: string | number} = {};

    fields().forEach(({name, value}) => {
      fieldsObj[name] = value
    })
    
    return fieldsObj
  }, [fields])
  const {
    control,
    handleSubmit,
    reset,
    formState: {
      isValid,
    },
  } = useForm({
    mode: "onChange",
    defaultValues: getDefaultValues()
  });

  useEffect(() => {
    reset(getDefaultValues())
  }, [getDefaultValues, reset, state])


  const closeHandler = () => {
    onClose();
  }
  const onSubmit = (formData: any) => {
    const payload = {
      rent: Number(formData.rent),
      marketRent: Number(formData.marketRent)
    }
    dispatch({
      type: ACTION.UPDATE_RENT,
      payload
    });
    closeHandler();
  }

  const isFormValid = isValid;

  return (
    <Modal
      active={open}
      onClose={closeHandler}
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Dialog loading={false}>
          <DialogTitle>
            <Typography as="small" block>Edit</Typography>
            Mortgage
          </DialogTitle>
          <DialogContent>
            <Grid gap={1}>
              {fields().map((field) => {
                const { 
                  validate = isNotEmpty,
                  name: fieldName,
                  type = 'number',
                  label,
                  before = '$'
                } = field 
                return (
                  <GridItem key={fieldName}>
                    <Controller
                      control={control}
                      name={fieldName as any}
                      rules={{ 
                        required: true, 
                        validate
                      }}
                      render={({
                        field: { onChange, value, name },
                        fieldState: { invalid },
                      }) => {
                        return (
                          <TextField
                            type={type}
                            before={before}
                            name={name}
                            id={name}
                            label={label}
                            isValid={!invalid}
                            required
                            fullWidth
                            value={value}
                            onChange={onChange}
                          />
                        )
                      }}
                    />
                  </GridItem>
                )
              })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              disabled={!isFormValid}
            >
              <Box as="span" display="flex" alignItems="center">
                Update
              </Box>
            </Button>
            <Button onClick={closeHandler}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </form>
    </Modal>
  )
}


export function RentContainer() {
  const {state} = useContext(HomeCountext);
  const [open, setOpen] = useState(false);
  const {
    recommendedRent,
    totalCost,
    rent,
    marketRent,
  } = state;

  const getRentTextHelper = () => {
    if (rent < recommendedRent && rent >= marketRent) {
      return `Recommended Rent: (${numberToDollars(recommendedRent)})`
    }
    if (rent < marketRent) {
      return `You are ${numberToDollars(rent - marketRent)} under market value (${numberToDollars(marketRent)})`
    }
    if (recommendedRent > marketRent) {
      return `You are ${numberToDollars(recommendedRent - marketRent)} over market value`
    }
    if (totalCost > rent) {
      return `Rental expenses is ${numberToDollars(totalCost)},  we are loosing (${numberToDollars(rent - totalCost)})`
    }
    return '';
  }

  const items = [
    {
      name: `Market Rent`,
      value: marketRent,
      uom: '$'
    },
    {
      name: `Recommended Rent`,
      value: recommendedRent,
      uom: '$'
    },
    {
      name: `Rent`,
      helperText: getRentTextHelper(),
      value: rent,
      uom: '$'
    }
  ]

  const openHandler = () => {
    setOpen(true)
  }

  const closeHandler = () => {
    setOpen(false)
  }

  return (
    <>
      <DataBox
      editClickHandler={openHandler}
        title='Rent'
        items={items}
        showSeverity
        total={[
          {
            title: 'Monthly Rent',
            value: rent,
            minTarget: totalCost - 1
          }
        ]}
      />
      <DialogContainer
        onClose={closeHandler}
        open={open}
      />
    </>
  )
}