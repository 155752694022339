import { useCallback, useContext, useEffect, useState } from "react"
import { useForm, Controller } from "react-hook-form";
import {
  isBiggerThanCero,
  isNotEmpty
} from "helpers";
import { HomeCountext } from "./Context"
import { DataBox } from "./DataBox";
import {
  Modal,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Box,
  Grid,
  GridItem,
  TextField,
  DialogActions,
  Button
} from "components";
import { ACTION } from './reducer';

interface DialogProps {
  open: boolean;
  onClose: () => void;
}

function DialogContainer(props: DialogProps) {
  const {
    open,
    onClose,
  } = props;
  const {state, dispatch} = useContext(HomeCountext);
  const {
    yearsTaxDeduction,
    marginalTaxRate,
    improvedValueAssessedValueRatio,
  } = state;

  const fields = useCallback((): {
    label: string,
    name: string,
    before: string,
    validate?: (v: string | number) => boolean,
    value: number | string,
    type?: "number" | "text" | "password" | undefined
  }[] => [
    {
      label: 'Years Tax Deduction',
      name: 'yearsTaxDeduction',
      before: 'Yr',
      validate: isBiggerThanCero,
      value: yearsTaxDeduction,
    },
    {
      label: 'Improved Value: Assessed Value Ratio',
      name: 'improvedValueAssessedValueRatio',
      before: '%',
      validate: isBiggerThanCero,
      value: improvedValueAssessedValueRatio,
    },
    {
      label: 'Marginal Tax Rate ',
      name: 'marginalTaxRate',
      before: '%',
      value: marginalTaxRate
    }
  ], [improvedValueAssessedValueRatio, marginalTaxRate, yearsTaxDeduction])

  const getDefaultValues = useCallback(() => {
    let fieldsObj: {[key: string]: string | number} = {};

    fields().forEach(({name, value}) => {
      fieldsObj[name] = value
    })
    
    return fieldsObj
  }, [fields])
  const {
    control,
    handleSubmit,
    reset,
    formState: {
      isValid,
    },
  } = useForm({
    mode: "onChange",
    defaultValues: getDefaultValues()
  });

  useEffect(() => {
    reset(getDefaultValues())
  }, [getDefaultValues, reset, state])


  const closeHandler = () => {
    onClose();
  }
  const onSubmit = (formData: any) => {
    const payload = {
      yearsTaxDeduction: Number(formData.yearsTaxDeduction),
      marginalTaxRate: Number(formData.marginalTaxRate),
      improvedValueAssessedValueRatio: Number(formData.improvedValueAssessedValueRatio),
    }
    dispatch({
      type: ACTION.UPDATE_TAXDEDUCTION,
      payload
    });
    closeHandler();
  }

  const isFormValid = isValid;

  return (
    <Modal
      active={open}
      onClose={closeHandler}
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Dialog loading={false}>
          <DialogTitle>
            <Typography as="small" block>Edit</Typography>
            Mortgage
          </DialogTitle>
          <DialogContent>
            <Grid gap={1}>
              {fields().map((field) => {
                const { 
                  validate = isNotEmpty,
                  name: fieldName,
                  type = 'number',
                  label,
                  before = '$'
                } = field 
                return (
                  <GridItem key={fieldName}>
                    <Controller
                      control={control}
                      name={fieldName as any}
                      rules={{ 
                        required: true, 
                        validate
                      }}
                      render={({
                        field: { onChange, value, name },
                        fieldState: { invalid },
                      }) => {
                        return (
                          <TextField
                            type={type}
                            before={before}
                            name={name}
                            id={name}
                            label={label}
                            isValid={!invalid}
                            required
                            fullWidth
                            value={value}
                            onChange={onChange}
                          />
                        )
                      }}
                    />
                  </GridItem>
                )
              })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              disabled={!isFormValid}
            >
              <Box as="span" display="flex" alignItems="center">
                Update
              </Box>
            </Button>
            <Button onClick={closeHandler}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </form>
    </Modal>
  )
}


export function TaxDeductionSummaryContainer() {
  const {state} = useContext(HomeCountext);
  const {
    marginalTaxRate,
    improvedValueAssessedValueRatio,
    yearsTaxDeduction
  } = state;
  const [open, setOpen] = useState(false);
  const items = [
    {
      name: `Years Tax Deduction`,
      value: yearsTaxDeduction,
      uom: 'Yr'
    },
    {
      name: `Improved Value: Assessed Value Ratio`,
      value: improvedValueAssessedValueRatio,
      uom: '%'
    },
    {
      name: 'Marginal Tax Rate',
      value: marginalTaxRate,
      uom: '%'
    }
  ]

  const openHandler = () => {
    setOpen(true)
  }

  const closeHandler = () => {
    setOpen(false)
  }

  return (
    <>
      <DataBox
        editClickHandler={openHandler}
        title='Tax Deduction Summary'
        items={items}
        total={[]}
      />
      <DialogContainer
        onClose={closeHandler}
        open={open}
      />
    </>
  )
}