import { Box, BoxProps } from '../Box';
import styled from 'styled-components';
import { Subtitle1, Typography } from '../Typography';
import { ReactComponent as CheckCircleOutlineOutlinedIcon } from '../../icons/CheckCircleOutlineOutlinedIcon.svg';
import { ReactComponent as ErrorOutlineOutlinedIcon } from '../../icons/ErrorOutlineOutlinedIcon.svg';
import { ReactComponent as InfoOutlinedIcon } from '../../icons/InfoOutlinedIcon.svg';
import { ReactComponent as WarningAmberOutlinedIcon } from '../../icons/WarningAmberOutlinedIcon.svg';

interface AlertProps extends BoxProps {
  title?: any;
  children?: any;
  variant?: 'contained' | 'outlined';
  severity?: 'error' | 'warning' | 'info' | 'success';
  gutterBottom?: boolean;
  showIcon?: boolean;
  icon?: any;
}

const getIconBySeverity = (severity: AlertProps['severity']) => {
  switch (severity) {
    case 'error':
      return ErrorOutlineOutlinedIcon;
    case 'warning':
      return WarningAmberOutlinedIcon;
    case 'info':
      return InfoOutlinedIcon;
    case 'success':
      return CheckCircleOutlineOutlinedIcon;
    default: return null;
  }
}

const IconWrapper = styled(Box)`
  width: ${props => props.theme.spacing.size[3]};
  svg {
    width: 100%;
  }
`;

export const AlertWrapper = styled(Box) <AlertProps>`
  border-radius: ${props => props.theme.borderRadius}px;
  margin: 0 0 ${props => props.gutterBottom ? props.theme.spacing.space(1) : 0} 0;
  border: 1px solid ${props => {
    return props.severity ? props.theme.palette[props.severity]?.main : props.theme.palette.divider;
  }};
  background-color: ${props => {
    if (props.variant === 'outlined') return '';
    return props.severity ? `${props.theme.palette[props.severity]?.main}80` : ''
  }};
  color: ${props => {
    if (props.variant === 'outlined') return '';
    if (props.severity) {
      return props.theme.palette[props.severity]?.contrastText || props.theme.palette.background.contrastText;
    }
    return props.theme.palette.background.contrastText;
  }};
  ${Subtitle1} {
    color: ${props => {
    if (props.variant === 'contained') return '';
    if (props.severity) {
      return props.theme.palette[props.severity]?.main || props.theme.palette.background.contrastText;
    }
    return props.theme.palette.background.contrastText;
  }};
  }
  ${Typography} {
    font-size: 0.875em;
  }
  ${IconWrapper} {
    svg * {
      fill: ${props => {
    if (props.variant === 'contained') return props.theme.palette.background.contrastText;
    if (props.severity) {
      return props.theme.palette[props.severity]?.main || props.theme.palette.background.contrastText;
    }
    return props.theme.palette.background.contrastText;
  }};
    }
  }
`;

export function Alert({ children, title, showIcon, ...rest }: AlertProps) {
  const Icon = getIconBySeverity(rest.severity);
  return (
    <AlertWrapper {...rest} display="flex">
      {showIcon && Icon && (
        <IconWrapper pr={rest.p} display="flex" alignItems="flex-start" justify="center">
          <Icon />
        </IconWrapper>
      )}
      <Box>
        {title && <Subtitle1>{title}</Subtitle1>}
        {typeof children === 'string' ? (
          <Typography as="div">
            {children}
          </Typography>
        ) : children}
      </Box>
    </AlertWrapper>
  )
}

export const AlertDfaultProps: AlertProps = {
  severity: 'info',
  variant: 'contained',
  showIcon: true,
  p: 1
}

Alert.defaultProps = AlertDfaultProps;