import { Section, Typography, Box, ScatterChart } from "components";
import { useState } from "react";
import styled from "styled-components";

const Content = styled(Box)`
  gap: 2rem;
  padding: 2rem;
  max-width: 960px;

  label {
    display: block;
    padding-bottom: .5em;
    font-size: .75em;
  }
  input {
    padding: 1em;
    width: 100%;
  }

  .formContainer {
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(3, 1fr);
  }
`;


type GetHypothesisArgs = string[];

const getHypothesis = ([t0, t1, x]: GetHypothesisArgs): number => {
  return parseFloat(t0) + parseFloat(t1) * parseFloat(x);
};

const getSquareError = (t0: string, t1: string, dataSet: string[][]) => {

  const sum = dataSet.reduce((acc, row, array) => {
    console.log(array, row);
    const item = getHypothesis([t0, t1, row[1]]) - (row[0] as any);
    return acc + Math.pow(item, 2);
  }, 0);

  return (1 / 2 * dataSet.length) * sum
}

const datSet = [
  ['1', '1'],
  ['2', '2'],
  ['3', '3']
]

// const x = getSquareError('0', '1', datSet)


export function AI() {
  const [x, setX] = useState('0');
  const [t0, setT0] = useState('0');
  const [t1, setT1] = useState('0');

  return (
    <Section>
      <Content>
        <Typography><strong>Hypothesis Formula:</strong> h(θ<sub>0</sub>, θ<sub>1</sub>) = θ<sub>0</sub> + θ<sub>1</sub><i>x</i></Typography>
        <Box pt={2}>
          <Box className="formContainer">
            <Box>
              <label htmlFor="t0">θ<sub>0</sub></label>
              <input value={t0} id="t0" onChange={(ev) => setT0(ev.target.value)} />
            </Box>
            <Box>
              <label htmlFor="t1">θ<sub>1</sub></label>
              <input value={t1} id="t1" onChange={(ev) => setT1(ev.target.value)} />
            </Box>
            <Box>
              <label htmlFor="x">X<sub>i</sub></label>
              <input value={x} id="x" onChange={(ev) => setX(ev.target.value)} />
            </Box>
          </Box>
          <Box pt={1}>
            <Typography><strong>Hypothesis:</strong> h(θ<sub>0</sub>, θ<sub>1</sub>) = {t0} + {t1} x {x}</Typography>
            {`Hypothesis: ${getHypothesis([t0, t1, x])}`}
          </Box>
          <Box>
            <ScatterChart
              title="Random Numbers"
              width={500}
              height={500}
              points={[
                ...datSet.map(([x, y]) => ({
                  valueX: Number(x),
                  valueY: Number(y),
                  label: 'as'
                }))
              ]}
              stepsY={3}
              stepsX={3}
              markerSize={6}
            />
          </Box>
        </Box>
      </Content>
    </Section>
  )
}