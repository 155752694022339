// import db from 'data/houses.json';
import { Typography, Box, Loading } from 'components';
import { useEffect, useReducer } from 'react';
// import { NotFound } from 'pages';
import { reducer, initialState, ACTION } from './reducer';
import styled from 'styled-components';
import { HomeCountext } from './Context';
import { ExpensesContainer } from './ExpensesContainer';
import { MortgageContainer } from './MortgageContainer';
import { RentContainer } from './RentContainer';
import { TotalsContainer } from './TotalsContainer';
import { DebtPaydownSummaryContainer } from './DebtPaydownSummaryContainer';
import { TaxDeductionSummaryContainer } from './TaxDeductionSummaryContainer';
import { getPropertyTaxes } from 'helpers';


const Wrapper = styled.div`
  display: grid;
  gap: ${props => props.theme.spacing.space('sm')};
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;

  > * > *:not(:first-child) {
    margin-top: ${props => props.theme.spacing.space('sm')};
  }
`;

export function House() {
  // const { id } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { street, city, state: pState, zip, beds, baths, sqft, } = state;
  useEffect(() => {
    const mortgage = {
      salesPrice: 195000,
      downpayment: 20,
      intrestRate: 5,
      loanTermYears: 30,
      pmi: 0,
    }
    dispatch({
      type: ACTION.INIT,
      payload: {
        // address
        street: '6800 Cypress Rd APT 401',
        city: 'Plantation',
        state: 'FL',
        zip: 33317,
        propertyType: 'singleFamily',
        //unit
        beds: 2,
        baths: 2,
        sqft: 0,
        // 
        rent: 2357,
        marketRent: 2357,
        //expenses
        tax: getPropertyTaxes(mortgage.salesPrice),
        insurance: 120,
        gas: 0,
        electric: 100,
        water: 80,
        sewer: 0,
        garbage: 150,
        lawn: 150,
        snow: 0,
        managment: 10,
        vacancy: 5,
        maintance: 10,
        //mortgage
        ...mortgage,
        closingCost: 0.05,
        // others
        rentGrowRate: 3.5,
        averageAnnualizedAppreciation: 3.5,
        // Five Year Tax Deduction Summary
        marginalTaxRate: 51,
        improvedValueAssessedValueRatio: 80,
      }
    })
  }, []);

  if (!street) return <Loading />

  return (
    <HomeCountext.Provider value={{
      state,
      dispatch
    }}>
      <Box p={4}>
        <Box pb={2}>
          <Typography as="h1" paddingBottom>
            {`${street}, ${city}, ${pState} ${zip}`}
          </Typography>
          <Typography>
            {`Beds: ${beds}, Baths: ${baths}, Sqft: ${sqft}`}
          </Typography>
        </Box>
        <Wrapper>
          <Box>
            <MortgageContainer />
            <TaxDeductionSummaryContainer />
          </Box>
          <Box>
            <ExpensesContainer />
          </Box>
          <Box>
            <RentContainer />
            <TotalsContainer />
          </Box>
          <Box>
            <DebtPaydownSummaryContainer />
          </Box>
        </Wrapper>
      </Box>
    </HomeCountext.Provider>
  )
}