import { Box, Typography } from 'components';
import { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ACTION, GameContext, STATUS } from './gameManager';
import { ReactComponent as Heart } from './assets/heart.svg';

const getTimePassed = (start?: Date) => start ? Math.abs((new Date()).getTime() - start.getTime()) / 1000 : 0;

const Header = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

const LiveCell = styled(Box)`
  color: red;
  svg * {
    fill: currentColor;
  }
`;

const LivesWrapper = styled(Box)`
  display: flex;
`;

const Lives = () => {
  const { state: { lives } } = useContext(GameContext);
  const hearts = new Array(lives).fill(null).map((_, index) => index + 1);
  return (
    <Box>
      <Typography as="small">Lives:</Typography>
      <LivesWrapper>
        {hearts.map((n) => <LiveCell key={n} p={.2}><Heart /></LiveCell>)}
      </LivesWrapper>
    </Box>
  )
}

const Timer = () => {
  const { dispatch, state: { sceneStarted, speed } } = useContext(GameContext);

  const timeLeft = useCallback(() => {
    const secondsPassed = getTimePassed(sceneStarted);
    if (secondsPassed >= speed) return 0;
    return Math.ceil(speed - secondsPassed);
  }, [sceneStarted, speed])

  const [time, setTime] = useState(timeLeft());

  useEffect(() => {
    let onAnimationFrame: number;
    const animate = () => {
      const secondsLeft = timeLeft();
      if (secondsLeft) {
        onAnimationFrame = requestAnimationFrame(animate);
      }
      setTime(timeLeft());
    }

    requestAnimationFrame(animate);
    return () => {
      cancelAnimationFrame(onAnimationFrame);
    }
  }, [timeLeft]);

  useEffect(() => {
    if (time === 0) {
      dispatch({
        type: ACTION.NEXT
      });
    }
  }, [dispatch, time])

  return (
    <Box className='timer' textAlign='center'>
      <Typography as="small">Time</Typography>
      <Typography as="h3">{time}</Typography>
    </Box>
  )
}

export function Interface() {
  const { state: { status, score, maxScore } } = useContext(GameContext);
  if (STATUS.PLAYING !== status) return null;
  return (
    <Header p={1}>
      <Lives />
      <Timer />
      <Box className='score' textAlign='right'>
        <Typography as="small">Score:</Typography>
        <Typography as="h3">{score}</Typography>
        {maxScore && (
          <>
            <Typography as="small">Max score:</Typography>
            <Typography as="small">{maxScore}</Typography>
          </>
        )}
      </Box>
    </Header>
  )
}