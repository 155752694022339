import { Section } from "components";
import { useEffect, useState } from "react";
import styled, { keyframes } from 'styled-components';

const animation1 = keyframes`
  from {
    stroke: green;
    stroke-width: 1px;
    transform: scale(1) rotate(0deg);
    rx: 0;
    stroke-dashoffset: 0;
  }

  to {
    stroke: red;
    stroke-width: 5px;
    transform: scale(2) rotate(360deg);
    rx: 10;
    stroke-dashoffset: 200px;
  }
`;

const animation2 = keyframes`
  from {
    stroke: green;
    transform: rotate(0deg);
    stroke-width: 1px;
  }

  to {
    stroke: red;
    transform: rotate(-360deg);
    stroke-width: 5px;
  }
`;

const Wrapper = styled(Section)`
  width: 100vw;
  height: 100vh;
`;

const SVG = styled.svg`
  width: 100%;
  height: 100%;

  #p1-line {
    animation-name: ${animation2};
    animation-timing-function: ease-in-out;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }
`;

type RectProps = {
  anchor: {
    x: number;
    y: number;
  }
}

const Rect = styled.rect<RectProps>`
  animation-name: ${animation1};
  animation-timing-function: ease-in-out;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  transform-origin: ${props => props.anchor.x}px ${props => props.anchor.y}px;
  stroke-dasharray: 200px;
`;

const Circle = styled.circle<RectProps>`
  fill: green;
  transform-origin: ${props => props.anchor.x}px ${props => props.anchor.y}px;
  transform: translateX(0) scale(1);
  transition: r .5s ease-in-out,
              fill 3s ease-in-out,
              transform 2s cubic-bezier(0.65, 0, 0.35, 1);
  &:hover {
    fill: blue;
    transform: translateX(100px) scale(2);
  }

`

export function SVGPage() {
  const [{ x, y, w, h }, setViewBox] = useState({
    x: 0,
    y: 0,
    w: 0,
    h: 0,
  });

  useEffect(() => {
    const updateViewBox = () => {
      setViewBox(prev => ({
        ...prev,
        w: window.innerWidth,
        h: window.innerHeight,
      }));
    }
    window.addEventListener('resize', updateViewBox);
    updateViewBox();

    return () => {
      window.removeEventListener('resize', updateViewBox);
    }
  }, [])

  return (
    <Wrapper>
      <SVG viewBox={`${x} ${y} ${w} ${h}`}>
        <defs>
          <pattern
            id="p1"
            width="50%" height="50%"
            viewBox={`0 0 50 50`}
            patternContentUnits="objectBoundingBox"
          >
            <line
              id="p1-line"
              x1={0}
              y1={25}
              x2={50}
              y2={25}
              stroke="red"
              strokeWidth={5}
              style={{
                transformOrigin: '25px 25px'
              }}
            />
          </pattern>
        </defs>
        <text x={10} y={20}>Svg Page</text>
        <Rect
          x={100}
          y={100}
          width={50}
          height={50}
          anchor={{
            x: 125,
            y: 125
          }}
          fill="url(#p1)"
        />
        <line
          x1={0} y1={300}
          x2={w} y2={300}
          stroke="red"
          strokeOpacity={.2}
        />
        <Circle
          anchor={{
            x: 115,
            y: 300
          }}
          cx={100}
          cy={300}
          r={30}
        />
      </SVG>
    </Wrapper>
  )
}