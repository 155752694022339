import styled, { keyframes } from 'styled-components';
import {Box, BoxProps} from '../Box'; 

const slideIn = keyframes`
  from {
    transform: translateX(-200%);
  }
  to {
    transform: translateX(0%);
  }
`;

const growBorder = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
`;

interface BreadcrumbItemPorps extends BoxProps {
  spacing: number;
}

const BreadcrumbItem = styled(Box)<BreadcrumbItemPorps>`
  display: flex;
  position: relative;
  line-height: 1em;
  overflow-y: hidden;
  transition: opacity 1s ease-in-out;

  span {
    transform: translateX(calc(-100% - ${props => props.theme.spacing.space(props.spacing)}));
    animation-name: ${slideIn};
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }

  &:not(:first-child) {
    padding-left: ${props => props.theme.spacing.space(props.spacing)};
    position: relative;
    margin-left: ${props => props.theme.spacing.space(props.spacing)};
    &:before {
      position: absolute;
      left: -${props => props.theme.spacing.space(props.spacing)};
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      margin-right: ${props => props.theme.spacing.space(props.spacing)};
      margin-left: ${props => props.theme.spacing.space(props.spacing)};
      background: red;
      animation-name: ${growBorder};
      animation-duration: .5s;
      animation-timing-function: ease-in-out;
    }
  }

  &:not(:first-child):last-child {
    opacity: 0.7;
  }
`;

export const BreadcrumbBase = styled.ol`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
  text-transform: uppercase;
  position: relative;
  font-weight: 700;
  font-size: ${props => props.theme.typography.small.fontSize};

  a {
    text-decoration: none;
    color: ${props => props.theme.palette.background.contrastText};
    position: relative;
    display: inline-block;
  }
`;

export interface BreadcrumbProps {
  items: {
    id: string;
    component: any
  }[];
}

export const Breadcrumb = (props: BreadcrumbProps) => {
  const { items } = props;

  return (
    <nav aria-label="breadcrumb">
      <BreadcrumbBase>
        {items.map((item, index) => (
          <BreadcrumbItem key={index} as="li" spacing={1}>
            <span>
              {item.component}
            </span>
          </BreadcrumbItem>
        ))}
      </BreadcrumbBase>
    </nav>
  )
}

Breadcrumb.defaultProps = {
  spacing: 0.25
}