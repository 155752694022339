import { Box, Button } from "components";
import { canvasToImg, msToTime } from "helpers";
import { useEffect, useState } from "react";
import styled from "styled-components";

const VideoRecorderWrapper = styled(Box)`
background-color: ${props => props.theme.palette.background.main};
color: ${props => props.theme.palette.background.contrastText};
border-radius: ${props => props.theme.borderRadius}px;
position: fixed;
bottom: 4vw;
left: 50%;
transform: translateX(-50%);
> * {
  width: 100px;
}
`;

export interface RecordCanvasContainerProps {
  show: boolean;
  canvas: HTMLCanvasElement;
}

export function CanvasRecorderContainer(props: RecordCanvasContainerProps) {
  const [frames, setFrames] = useState<string[]>([]);
  const [record, setRecord] = useState(false);
  const { canvas, show } = props;

  useEffect(() => {
    let recorder: NodeJS.Timeout;
    if (record) {
      recorder = setInterval(() => {
        // 
        const frame = canvasToImg({
          canvas,
          extension: 'png'
        })
        setFrames(prevFrames => [...prevFrames, frame]);
        console.log('new image')
      }, 100)
    }
    if (!show) {
      setFrames([]);
      setRecord(false);
    }
    return () => {
      if (recorder) {
        clearInterval(recorder);
      }
    }
  }, [canvas, record, show]);


  const recordHandler = () => {
    setRecord((prev) => !prev);
    // 
  }

  if (!show) return null;

  return (
    <VideoRecorderWrapper
      p={0.5} display="flex"
      alignItems="center"
      justify="center"
    >
      <Box>
        <Button
          fullWidth
          onClick={recordHandler}
        >
          {`${record ? 'Pause' : 'Record'}`}
        </Button>
      </Box>
      <Box>
        <Button
          fullWidth
          onClick={() => {
            setFrames([]);
          }}
        >
          Clear
        </Button>
      </Box>
      <Box alignItems="center" justify="center" textAlign="center">
        {msToTime(frames.length * 100)}
      </Box>
    </VideoRecorderWrapper>
  )
}