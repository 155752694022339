import styled from 'styled-components';

const Line = styled.div`
  height: 2px;
  background: ${props => props.theme.palette.background.contrastText};
`;

const Wrapper = styled.div`
  display: flex;
  width: 44px;
  height: 44px;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
`;

export interface BurgerProps {
  active?: boolean;
  onClick?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export function Burger(props: BurgerProps) {
  return (
    <Wrapper role="button" {...props} tabIndex={0}>
      <Line />
      <Line />
      <Line />
    </Wrapper>
  )
} 