import { RefObject, useEffect, useState } from "react";


interface UseIntersectionObserverProps {
  ref: RefObject<Element>;
  threshold?: number;
  root?: null;
  rootMargin?: number;
  freezeOnceVisible?: boolean;
}
export const useIntersectionObserver = (props: UseIntersectionObserverProps) => {
  const {
    ref = null,
    threshold = 0,
    root = null,
    rootMargin = 0,
    freezeOnceVisible = false,
  } = props;

  const [entry, setEntry] = useState<IntersectionObserverEntry>();
  const frozen = entry?.isIntersecting && freezeOnceVisible

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry)
  }

  useEffect(() => {
    const node = ref?.current // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver

    if (!hasIOSupport || frozen || !node) return

    const observerParams = { threshold, root, rootMargin: `${rootMargin}%` }
    const observer = new IntersectionObserver(updateEntry, observerParams)

    observer.observe(node)

    return () => observer.disconnect()

  }, [threshold, root, rootMargin, frozen, ref])

  return entry;
}