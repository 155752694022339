import styled, { BreakPoints } from 'styled-components';

interface ContainerProps{
  size?: keyof BreakPoints | 'full';
  center?: boolean;
}

export const Container = styled.div<ContainerProps>`
  max-width: ${({size, theme}) => {
    if(size === 'full') return 'initial';
    if(size) {
      return `calc(${theme.breakPoints[size] ?? ''} + ${theme.pageGutter} + ${theme.pageGutter})`;
    }
    return theme.pageGutter
  }};
  margin: 0 ${props => props.center ? 'auto' : ''};
  padding: 0 ${props => props.theme.pageGutter};
`;

Container.defaultProps = {
  size: 'lg'
}

