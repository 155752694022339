import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Backdrop, BackdropProps } from '../Backdrop';
import Portal from '../Portal';

const ModalWrapper = styled.div`
  position: fixed;
  z-index: ${(props): number => props.theme.zIndex.modal};
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export interface ModalProps {
  scroll?: boolean;
  children: any;
  active: boolean;
  onClose?(): void;
  backdropProps?: BackdropProps;
  unmountOnExit?: boolean;
}

export const Modal = (props: ModalProps): JSX.Element => {
  const {
    active,
    children,
    onClose,
    backdropProps,
    scroll,
  } = props;
  const content = useRef<HTMLDivElement | null>(null);
  const [show, setShow] = useState(active);

  const closeEventHandler = (): void => {
    if (typeof onClose === 'function') {
      onClose();
      return;
    }
    setShow(false);
  };

  useEffect(() => {
    setShow(active);
  }, [active]);

  useEffect(() => {
    const keydownEventHandler = (ev: KeyboardEvent): void => {
      const { keyCode } = ev;
      if (keyCode === 27) {
        if (typeof onClose === 'function') {
          onClose();
          return;
        }
        setShow(false);
      }
    };

    if (show && !scroll) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('keydown', keydownEventHandler);
    }
    return (): void => {
      document.removeEventListener('keydown', keydownEventHandler);
      if (show) {
        document.body.style.overflow = '';
      }
    };
  }, [onClose, show, content, scroll]);

  useEffect(() => {
    if(show) {
      // TODO work on focus this is not working
      if (content.current) {
        content.current.focus();
      }
    }
  }, [show])

  return (
    <Portal>
      {show && (
        <ModalWrapper role="presentation" ref={content} tabIndex={0}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Backdrop {...backdropProps} onClick={closeEventHandler} />
          {children}
        </ModalWrapper>
      )}
    </Portal>
  );
};

Modal.defaultProps = {
  onClose: () => {},
  active: false,
  backdropProps: {}
}
