import { getMortgageValue, getPercentageValue, getPMIValuePerMonth } from "helpers";

interface TotalDeductions {
  year: number;
  depreciationStraightLine: number;
  totalInterestPaid: number; 
  totalDeductions: number; 
}

interface InitialState extends Property {
  // totals
  recommendedRent: number;
  operatingExpenses: number;
  mortgageTotal: number;
  buyPayment: number;
  totalCost: number;
  monthlyNetOperatingIncome: number;
  annualizedNetOperatingIncome: number;
  capitalizationRate: number;
  monthlyNet: number;
  annualizedNet: number;
  annualizedReturnOnInvestment: number;
  // Year Tax Deduction Summary
  yearsTaxDeduction: number; 
  marginalTaxRate: number;
  improvedValueAssessedValueRatio: number;
  totalDeductions: TotalDeductions[];
  avgYearlyTaxSavings: number;
  percentageAnnualROIonTaxSavings: number;
}

export const initialState: InitialState = {
  // address
  street: '',
  city: '',
  state: '',
  zip: 0,
  propertyType: 'singleFamily',
  //unit
  beds: 0,
  baths: 0,
  sqft: 0,
  // Rent
  rent: 0,
  marketRent: 0,
  //expenses
  tax: 0,
  insurance: 0,
  gas: 0,
  electric: 0,
  water: 0,
  sewer: 0,
  garbage: 0,
  lawn: 0,
  snow: 0,
  managment: 0,
  vacancy: 0,
  maintance: 0,
  //mortgage
  salesPrice: 0,
  downpayment: 0,
  intrestRate: 0,
  loanTermYears: 0,
  pmi: 0,
  // --- review
  closingCost: 0,
  // others
  rentGrowRate: 0,
  averageAnnualizedAppreciation: 0,
  // Five Year Tax Deduction Summary
  yearsTaxDeduction: 5,
  marginalTaxRate: 0,
  improvedValueAssessedValueRatio: 0,
  totalDeductions: [],
  avgYearlyTaxSavings: 0,
  percentageAnnualROIonTaxSavings: 0,
  // totals
  recommendedRent: 0,
  operatingExpenses: 0,
  mortgageTotal: 0,
  buyPayment: 0,
  totalCost: 0,
  monthlyNetOperatingIncome: 0,
  annualizedNetOperatingIncome: 0,
  capitalizationRate: 0,
  monthlyNet: 0,
  annualizedNet: 0,
  annualizedReturnOnInvestment: 0,
}

const getMonthlyNetOperatingIncome = (
  rent: number,
  operatingExpenses: number,
) => {
  return rent - operatingExpenses;
}

const getExpensesFromProperty = (property: Property) => {
  const {
    tax,
    insurance,
    gas,
    electric,
    water,
    sewer,
    garbage,
    lawn,
    snow,
    managment,
    vacancy,
    maintance,
    rent,
  } = property;
  const managmentTotal = rent * (managment / 100);
  const vacancyTotal = rent * (vacancy / 100);
  const maintanceTotal = rent * (maintance / 100);
  const monthlyTaxes = tax / 12;
  const expenses = [
    monthlyTaxes,
    insurance,
    gas,
    electric,
    water,
    sewer,
    garbage,
    lawn,
    snow,
    managmentTotal,
    vacancyTotal,
    maintanceTotal
  ]
  return expenses.reduce((acc, val) => acc += val, 0);
}

const getMortgageFromProperty = (property: Property) => {
  const {
    salesPrice,
    downpayment,
    intrestRate,
    loanTermYears,
  } = property;

  const downPayment = salesPrice * (downpayment / 100);
  const pmiValue = getPMIValuePerMonth(salesPrice, downPayment);
  const mortgage = getMortgageValue({
    salesPrice,
    downpayment,
    intrestRate,
    loanTermYears,
  });

  return mortgage + pmiValue;
}

const getTotalDeductions = (years: number): TotalDeductions[] => {
  return Array(years).fill(undefined).map((_, index) => index + 1).map((year) => {
    return (
      {
        year,
        depreciationStraightLine: 0,
        totalInterestPaid: 0,
        totalDeductions: 0 
      }
    )
  })
}

export enum ACTION {
  'INIT',
  'UPDATE_MORTGAGE',
  'UPDATE_EXPENSES',
  'UPDATE_RENT',
  'UPDATE_TAXDEDUCTION'
}

interface UPDATE_MORTGAGE {
  type: ACTION.UPDATE_MORTGAGE,
  payload: Pick<InitialState, 'salesPrice' | 'downpayment' | 'intrestRate' | 'loanTermYears' | 'closingCost'>
}

interface UPDATE_EXPENSES {
  type: ACTION.UPDATE_EXPENSES,
  payload: Pick<InitialState, 
    'tax' | 
    'insurance' | 
    'gas' | 
    'electric' | 
    'water' |
    'sewer' |
    'garbage' |
    'lawn' |
    'snow' |
    'managment' |
    'vacancy' |
    'maintance'
  >
}

interface UPDATE_RENT {
  type: ACTION.UPDATE_RENT,
  payload: Pick<InitialState, 'rent' | 'marketRent'>
}

interface INIT {
  type: ACTION.INIT,
  payload: Property
}

interface UPDATE_TAXDEDUCTION {
  type: ACTION.UPDATE_TAXDEDUCTION,
  payload: Pick<InitialState, 'marginalTaxRate' | 'improvedValueAssessedValueRatio' | 'yearsTaxDeduction'>
}

export type ACTIONS = INIT | UPDATE_MORTGAGE | UPDATE_EXPENSES | UPDATE_RENT | UPDATE_TAXDEDUCTION;

export const reducerState = (state: typeof initialState = initialState, action: ACTIONS) => {
  switch (action.type) {
    case ACTION.INIT:
      return {
        ...initialState,
        ...action.payload
      }
    case ACTION.UPDATE_MORTGAGE:
    case ACTION.UPDATE_EXPENSES:
    case ACTION.UPDATE_RENT:
    case ACTION.UPDATE_TAXDEDUCTION:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}

export const reducer = (state: typeof initialState = initialState, action: ACTIONS) => {
  const baseState = reducerState(state, action);
  const operatingExpenses = getExpensesFromProperty(baseState);
  const mortgageTotal = getMortgageFromProperty(baseState);
  const monthlyNetOperatingIncome = getMonthlyNetOperatingIncome(
    baseState.rent,
    operatingExpenses
  )
  const annualizedNetOperatingIncome = monthlyNetOperatingIncome * 12;
  const capitalizationRate = (annualizedNetOperatingIncome / baseState.salesPrice) * 100;
  // 
  const monthlyNet = monthlyNetOperatingIncome - mortgageTotal;
  const annualizedNet = monthlyNet * 12;
  const closingCostNet = baseState.salesPrice * baseState.closingCost;
  const downPaymentNet = baseState.salesPrice * (baseState.downpayment / 100);
  const annualizedReturnOnInvestment = annualizedNet / (
    getPercentageValue(baseState.salesPrice, baseState.downpayment)
    + closingCostNet
  ) * 100;

  const totalCost = operatingExpenses + mortgageTotal;
  const recommendedRent = baseState.salesPrice * .01;
  const buyPayment = downPaymentNet + closingCostNet;
  const totalDeductions = getTotalDeductions(baseState.yearsTaxDeduction);

  return {
    ...baseState,
    monthlyNetOperatingIncome,
    annualizedNetOperatingIncome,
    operatingExpenses,
    mortgageTotal,
    buyPayment,
    capitalizationRate,
    monthlyNet,
    annualizedNet,
    annualizedReturnOnInvestment,
    recommendedRent,
    totalCost,
    totalDeductions
  }
}