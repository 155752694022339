import { useReducer } from 'react';
import styled from 'styled-components';
import { reducer, STATUS, initialState, GameContext } from './gameManager';
import { Interface } from './Interface';
import { GameOverScene, GameScene, HomeScene } from './scenes';
import { LeaderboardScene } from './scenes/LeaderboardScene';


const Layout = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #a3ff3b;
`;

export function GameContainer() {

  const [state, dispatch] = useReducer(
    reducer,
    initialState
  );

  const { status } = state;

  return (
    <GameContext.Provider value={{
      state,
      dispatch
    }}>
      <Layout>
        <Interface />
        {STATUS.START === status && <HomeScene />}
        {STATUS.PLAYING === status && <GameScene />}
        {STATUS.OVER === status && <GameOverScene />}
        {STATUS.LEADERBOARD === status && <LeaderboardScene />}
      </Layout>
    </GameContext.Provider>
  )
}