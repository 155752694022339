import { useContext } from 'react';
import styled from 'styled-components';
import { ACTION, GameContext } from '../gameManager';

const Cell = styled.div`
  cursor: pointer;
  font-size: 20px;
`;

interface GameProps {
  cols: number;
  rows: number;
}

const Game = styled.div<GameProps>`
  display: grid;
  grid-template-columns: repeat(${props => props.cols}, 1fr);
  grid-template-rows: repeat(${props => props.rows}, 1fr);
  gap: 1em;
`;

const DotCell = (props: { col: number, row: number }) => {
  const { col, row } = props;
  const { dispatch, state: { figure, correctDot } } = useContext(GameContext);
  const active = col === correctDot.col && row === correctDot.row;
  const [baseIcon, activeIcon] = figure;

  return (
    <Cell
      onClick={() => {
        dispatch({
          type: ACTION.SELECT,
          payload: {
            col,
            row
          }
        })
      }}
    >
      {active ? baseIcon : activeIcon}
    </Cell>
  );
}

export function GameScene() {
  const { state } = useContext(GameContext);
  const { dots, complexity } = state;

  return (
    <Game cols={complexity} rows={complexity}>
      {dots.map((cols) => cols
        .map((value) => {
          const [col, row] = value.split(',')
          return <DotCell row={Number(row)} col={Number(col)} key={value} />
        }))}
    </Game>
  )
}