import { useEffect, useReducer } from "react"

enum ACTION {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

type LOADING = {
  type: ACTION.LOADING
}

type SUCCESS = {
  type: ACTION.SUCCESS,
  payload: any;
}

type ERROR = {
  type: ACTION.ERROR,
  payload: {
    statusCode: number;
    message: string;
  };
}

type ACTIONS = LOADING | SUCCESS | ERROR;

interface UseFetchProps {
  url: string;
}

interface UseFetchResponse<T> {
  loading: boolean;
  data: T | null,
  error: {
    statusCode: number;
    message: string;
  } | null
}

const initalState = {
  loading: true,
  data: null,
  error: null
}

function reducer<T>(state: UseFetchResponse<T>, action: ACTIONS): UseFetchResponse<T> {
  switch (action.type) {
    case ACTION.LOADING:
      return { ...initalState, loading: true }
    case ACTION.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      }
    case ACTION.ERROR:
      return {
        ...state,
        error: action.payload
      }
  }
}

export function useFetch<T>(props: UseFetchProps): UseFetchResponse<T> {
  const [state, dispatch] = useReducer(reducer, initalState);
  const { url } = props;

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    dispatch({
      type: ACTION.LOADING
    });
    fetch(url, { signal })
      .then((response) => response.json())
      .then((payload) => {
        dispatch({
          type: ACTION.SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: ACTION.ERROR,
          payload: {
            statusCode: 500,
            message: `${error}`
          }
        });
      });
    return () => {
      controller.abort();
    }
  }, [url]);

  return state as any
}